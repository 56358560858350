import React, { useState } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { AuthContext } from "../context/auth";

import HomePage from '../Pages/HomePage'
import MesurePage from '../Pages/MesurePage';
import TerminalPage from '../Pages/TerminalPage'
import UserPage from '../Pages/UserPage';
import SitePage from '../Pages/SitePage';
import EntreprisePage from '../Pages/EntreprisePage';
import SavPage from '../Pages/SavPage'
import PrivateRoute from './PrivateRoute';
// import ProfilPage from '../Pages/ProfilPage';

function AppRouters() {
  
  return (
      
        <Router>
          <PrivateRoute exact path="/" component={MesurePage} />
          <PrivateRoute path="/mesures" component={MesurePage} />
          <PrivateRoute path="/terminaux" component={TerminalPage} />
          <PrivateRoute path="/users" component={UserPage} />
          <PrivateRoute path="/sites" component={SitePage} />
          <PrivateRoute path="/entreprises" component={EntreprisePage} />
          <PrivateRoute path="/sav" component={SavPage} />
          {/* <PrivateRoute path="/profil" component={ProfilPage} /> */}


          {/* <Route exact path="/" render={() => (<HomePage cookies={this.props.cookies} />)} />
        <Route path="/mesures" render={() => (<MesurePage cookies={this.props.cookies} />)} />
        <Route path="/terminaux" render={() => (<TerminalPage cookies={this.props.cookies} />)} />
        <Route path="/users" render={() => (<UserPage cookies={this.props.cookies} />)} />
        <Route path="/sites" render={() => (<SitePage cookies={this.props.cookies} />)} />
        <Route path="/entreprises" render={() => (<EntreprisePage cookies={this.props.cookies} />)} /> */}
          {/* <Route path="/about" component={About} /> */}
        </Router>
      
    
  )
}

// function About() {
//   return <h2>About</h2>;
// }

export default AppRouters
