import React, { useState, useEffect } from 'react'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import imgLogo from '../static/images/piink3D.png'
import imgLogoHub from '../static/images/home001 - E031.png'
import imgLogoClose from '../static/images/SIGLE-piink3D.png'
import iconMesureSelected from '../static/images/cube001 - E029.png'
import iconMesure from '../static/images/cube.png'
import iconTerminal from '../static/images/smartphone001 - E108.png'
import iconUser from '../static/images/fingerprint001 - E237.png'
import iconSite from '../static/images/pin001 - E017.png'
import iconEnt from '../static/images/home001 - E031.png'
import iconTerminalSelected from '../static/images/phone-hover.png'
import iconUserSelected from '../static/images/print-hover.png'
import iconSiteSelected from '../static/images/pin-hover.png'
import iconEntSelected from '../static/images/home-hover.png'
import iconAssist from '../static/images/assist.png'
import iconAssistSelected from '../static/images/assist-hover.png'
import imgBasPage from '../static/images/BG-website.png'
import imgbtnClose from '../static/images/quitter.png'
import imgbtnOpen from '../static/images/open.png'

import { useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import axios from '../plugins/axios';

// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';

// const styles = theme => ({
//     root: {
//         flexGrow: 1,
//     }
// });

const drawerWidth = 250;

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        border: "1px solid #D5D5D5",
        opacity: 1
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerClose: {
        width: "60px",
        flexShrink: 0,
    },
    drawerClosePaper: {
        width: "60px"
    },
    divLogo: {
        // width: "200px",
        // minWidth: "200px",
        // height: "70px",
        // backgroundImage: `url(${img +'/logo-PIINK-3D.png'})`
        // backgroundImage: `url(${imgLogo})`,
        // backgroundSize: "contain",
        // backgroundRepeat: "no-repeat",
        marginTop: "10px",
        // marginLeft: "45px",
        marginBottom: "10px",
    },
    divLogoClose: {
        // height: "47px",
        // backgroundImage: `url(${img +'/logo-PIINK-3D.png'})`
        // backgroundImage: `url(${imgLogoClose})`,
        // backgroundSize: "contain",
        // backgroundRepeat: "no-repeat",
        marginTop: "20px",
        marginBottom: "35px",
    },
    onglet: {
        // textAlign: "center"
        marginBottom: "15px"
    },
    iconClass: {
        marginLeft: "30px",
        // margin: "10px"
    },
    iconClassClose: {
        marginBottom: "10px"
    },
    txtBasPage: {
        marginTop: "-150px",
        marginLeft: "5px"
    },
    btnBlue: {
        background: "#25C2F2",
        color: "#FFF",
        marginTop: "10px"
    },
    btnOpenNav: {
        marginTop: "10px",
        width: "60px",
        alignSelf: "center",
        minWidth: "60px"
    },
    btnCloseNav: {
        width: "60px",
        marginRight: "105px"
    },
    btnHomeNav: {
        width: "60px"
    },
    divHeaderNavBar: {
        width: "210px",
        display: "flex"
    },
    intitulPage: {
        marginLeft: "10px"
    },
    mouseHoverText0: {
        position: "fixed",
        left:"75px",
        top: "165px",//+65
        backgroundColor: "#F2F2F2",
        padding: "5px",
        borderRadius: "10px"
    },
    mouseHoverText1: {
        position: "fixed",
        left:"75px",
        top: "230px",//+65
        backgroundColor: "#F2F2F2",
        padding: "5px",
        borderRadius: "10px"
    },
    mouseHoverText2: {
        position: "fixed",
        left:"75px",
        top: "295px",//+65
        backgroundColor: "#F2F2F2",
        padding: "5px",
        borderRadius: "10px"
    },
    mouseHoverText3: {
        position: "fixed",
        left:"75px",
        top: "360px",//+65
        backgroundColor: "#F2F2F2",
        padding: "5px",
        borderRadius: "10px"
    },
    mouseHoverText4: {
        position: "fixed",
        left:"75px",
        top: "425px",//+65
        backgroundColor: "#F2F2F2",
        padding: "5px",
        borderRadius: "10px"
    },
    mouseHoverText5: {
        position: "fixed",
        left:"75px",
        top: "490px",//+65
        backgroundColor: "#F2F2F2",
        padding: "5px",
        borderRadius: "10px"
    }
})

function NavBar(props) {

    // const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { t, i18n } = useTranslation();
    const classes = useStyles();

    const [openNav, setOpenCloseNav] = React.useState(true)

    const sendData = val => {
        props.pageCallBack(val)
    }

    // function clickGoWebsite(){
    //     // console.log("click GO to WEBSITE")
    //     window.location = "https://piink-teknology.com"
    // }
    
    function changeNav(val){
        // console.log("val of navbar : "+val)
        // console.log(props.dataFromParent)
        setOpenCloseNav(val)
        sendData(val)
    }

    
    useEffect(() => {
        console.log("call getItem")
        setOpenCloseNav(JSON.parse(window.localStorage.getItem('openNav')))
    }, [])

    useEffect(() => {
        window.localStorage.setItem('openNav', openNav);
    }, [openNav]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    var loc = ""
    async function backHub(){
        loc = await axios.customAxios.get(axios.customAxios.defaults.baseURL + "/app/getUrlPiinkHub")
        window.location = loc.data.urlPiinkHub
    }

    const [isShown, setShowDash] = useState(false);
    const [valueHover, setValueHover] = useState("")
    const [idHover, setIdHover] = useState(null)

    const handleShowDash = (val, value, id) => {
        // console.log(value)
        setValueHover(value)
        setIdHover(id)
        setShowDash(val)        
    }

    const renderHover = () => {
        switch(idHover) {
            case 0:
                return (
                    <div className={classes.mouseHoverText0}>
                        {valueHover}
                    </div>
                );
            case 1:
                    return (
                        <div className={classes.mouseHoverText1}>
                            {valueHover}
                        </div>
                    );
            case 2:
                return (
                    <div className={classes.mouseHoverText2}>
                        {valueHover}
                    </div>
                );
            case 3:
                return (
                    <div className={classes.mouseHoverText3}>
                        {valueHover}
                    </div>
                );
            case 4:
                return (
                    <div className={classes.mouseHoverText4}>
                        {valueHover}
                    </div>
                );
            case 5:
                return (
                    <div className={classes.mouseHoverText5}>
                        {valueHover}
                    </div>
                );
        }
        
        
    }
        
    return (
        <div className={classes.root}>
            {openNav === true ? 
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                {/* add logo */}
                    <div className={classes.divHeaderNavBar}>
                        <Button className={classes.btnCloseNav} onClick={() => changeNav(false)}><img src={imgbtnClose} /></Button>
                        <Button className={classes.btnCloseNav} onClick={() => backHub()}><img src={imgLogoHub} /></Button>
                </div>
                <div>
                    <img className={classes.divLogo}
                        src={imgLogo} />
                </div>
                <List>
                    <Link  href="/">
                        <ListItem 
                            button 
                            key={t('NavBar.mesure')}
                            className={classes.onglet}>
                            {props.dataFromParent === "mesurePage" ? 
                            <img 
                                className={classes.iconClass}
                                src={iconMesureSelected} />
                            :
                            <img 
                                className={classes.iconClass}
                                src={iconMesure} />}
                            <ListItemText primary={ t('NavBar.mesure')} className={classes.intitulPage}/>
                        </ListItem>
                    </Link>
                    
                    <Link href="/terminaux">
                        <ListItem
                            button
                            key={t('NavBar.term')}
                            className={classes.onglet}>
                            {props.dataFromParent === "terminalPage" ? 
                            <img
                            className={classes.iconClass}
                            src={iconTerminalSelected} /> :
                            <img
                                className={classes.iconClass}
                                src={iconTerminal} />}
                            <ListItemText primary={ t('NavBar.term')} className={classes.intitulPage}/>
                        </ListItem>
                    </Link>

                        {props.dataIsUser === "user" ?
                            <p></p>
                            :
                            <Link href="/entreprises">
                                <ListItem
                                    button
                                    key={t('NavBar.ent')}
                                    className={classes.onglet}>
                                    {props.dataFromParent === "entreprisePage" ?
                                        <img
                                            className={classes.iconClass}
                                            src={iconEntSelected} />
                                        :
                                        <img
                                            className={classes.iconClass}
                                            src={iconEnt} />

                                    }

                                    <ListItemText primary={t('NavBar.ent')} className={classes.intitulPage} />
                                </ListItem>
                            </Link>
                        }
                    
                    <Link href="/sites">
                        <ListItem
                            button
                            key={t('NavBar.site')}
                            className={classes.onglet}>
                            {props.dataFromParent === "sitePage" ?
                            <img
                                className={classes.iconClass}
                                src={iconSiteSelected} />
                            :
                            <img
                                className={classes.iconClass}
                                src={iconSite} />}
                            <ListItemText primary={ t('NavBar.site')} className={classes.intitulPage}/>
                        </ListItem>
                    </Link>

                        <Link href="/users">
                            <ListItem
                                button
                                key={t('NavBar.user')}
                                className={classes.onglet}>
                                {props.dataFromParent === "userPage" ?
                                    <img
                                        className={classes.iconClass}
                                        src={iconUserSelected} />
                                    :
                                    <img
                                        className={classes.iconClass}
                                        src={iconUser} />}
                                <ListItemText primary={t('NavBar.user')} className={classes.intitulPage} />
                            </ListItem>
                        </Link>

                                <Link href="/sav">
                                    <ListItem
                                        button
                                        key={t('NavBar.sav')}
                                        className={classes.onglet}>
                                        {props.dataFromParent === "savPage" ?
                                            <img
                                                className={classes.iconClass}
                                                src={iconAssistSelected} />
                                            :
                                            <img
                                                className={classes.iconClass}
                                                src={iconAssist} />}
                                        <ListItemText primary={t('NavBar.sav')} className={classes.intitulPage} />
                                    </ListItem>
                                </Link>
                        
                    {/* <Link href="/profil">
                        <ListItem
                            button
                            key={t('NavBar.profil')}
                            className={classes.onglet}>
                            {props.dataFromParent == "profilPage" ?
                                <img
                                    className={classes.iconClass}
                                    src={iconUserSelected} />
                                :
                                <img
                                    className={classes.iconClass}
                                    src={iconUser} />}
                                <ListItemText primary={t('NavBar.profil')} className={classes.intitulPage} />
                        </ListItem>
                    </Link> */}
                    
                    
                </List>
                {/* img acceder au site */}
                <div>
                    <img
                        className={classes.iconClass}
                        src={imgBasPage} />
                    <div className={classes.txtBasPage}>
                        <p>
                            {t('NavBar.moreInf')}<br />
                            {t('NavBar.visit')}
                        </p>
                            <Button className={classes.btnBlue} onClick={() => window.open("https://piink-teknology.com", "_blank")}>{t('NavBar.go')}</Button>
                    </div>
                </div>

                {/* <List component="nav">
                    <ListItem component="div">
                        <ListItemText inset>
                            <TypoGraphy color="inherit">
                                <Link href="/mesures"> {t('NavBar.mesure')} </Link>
                            </TypoGraphy>
                        </ListItemText>

                        <ListItemText inset>
                            <TypoGraphy color="inherit">
                                <Link href="/terminaux"> {t('NavBar.term')} </Link>
                            </TypoGraphy>
                        </ListItemText>

                        <ListItemText inset>
                            <TypoGraphy color="inherit">
                                <Link href="/users"> {t('NavBar.user')} </Link>
                            </TypoGraphy>
                        </ListItemText>

                        <ListItemText inset>
                            <TypoGraphy color="inherit">
                                <Link href="/sites"> {t('NavBar.site')} </Link>
                            </TypoGraphy>
                        </ListItemText>

                        <ListItemText inset>
                            <TypoGraphy color="inherit">
                                <Link href="/entreprises"> {t('NavBar.ent')} </Link>
                            </TypoGraphy>
                        </ListItemText>
                    </ListItem >

                </List> */}
                {/* <Button className={classes.btnCloseNav} onClick={() => changeNav(false)}><img src={imgbtnClose} /></Button> */}
            </Drawer>
            :
            <Drawer className={classes.drawerClose}
                variant="permanent"
                classes={{
                    paper: classes.drawerClosePaper,
                }}
                anchor="left">
                {/* <Button className={classes.btnCloseNav} onClick={() => changeNav(true)}>Open</Button> */}
                <Button className={classes.btnOpenNav} onClick={() => changeNav(true)}><img src={imgbtnOpen} /></Button>
                <div>
                    <img className={classes.divLogoClose}
                        src={imgLogoClose} />
                </div>
                <List>
                    <Link href="/">
                        <ListItem
                        button
                        key={t('NavBar.mesure')}
                        className={classes.onglet}
                        onMouseEnter={()=> handleShowDash(true,t('NavBar.mesure'), 0)}
                        onMouseLeave={() => handleShowDash(false,t('NavBar.mesure'), 0)}>
                            {props.dataFromParent == "mesurePage" ? 
                            <img 
                                className={classes.iconClassClose}
                                src={iconMesureSelected} />
                            :
                            <img 
                                className={classes.iconClassClose}
                                src={iconMesure} />}
                        </ListItem>
                    </Link>
                    <Link href="/terminaux">
                        <ListItem
                        button
                        key={t('NavBar.term')}
                        className={classes.onglet}
                        onMouseEnter={()=> handleShowDash(true,t('NavBar.term'), 1)}
                        onMouseLeave={() => handleShowDash(false,t('NavBar.term'), 1)}>
                            {props.dataFromParent == "terminalPage" ? 
                            <img
                            className={classes.iconClassClose}
                            src={iconTerminalSelected} /> :
                            <img
                                className={classes.iconClassClose}
                                src={iconTerminal} />}
                        </ListItem>
                    </Link>
                        {props.dataIsUser === "user" ?
                            <div>
                            <Link href="/sites">
                            <ListItem
                                button
                                key={t('NavBar.site')}
                                className={classes.onglet}
                                onMouseEnter={()=> handleShowDash(true,t('NavBar.site'), 2)}
                                onMouseLeave={() => handleShowDash(false,t('NavBar.site'), 2)}>
                                {props.dataFromParent === "sitePage" ?
                                    <img
                                        className={classes.iconClassClose}
                                        src={iconSiteSelected} />
                                    :
                                    <img
                                        className={classes.iconClassClose}
                                        src={iconSite} />}
                                </ListItem>
                            </Link>

                            <Link href="/users">
                                <ListItem
                                    button
                                    key={t('NavBar.user')}
                                    className={classes.onglet}
                                    onMouseEnter={()=> handleShowDash(true,t('NavBar.user'), 3)}
                                    onMouseLeave={() => handleShowDash(false,t('NavBar.user'), 3)}>
                                    {props.dataFromParent === "userPage" ?
                                        <img
                                            className={classes.iconClassClose}
                                            src={iconUserSelected} />
                                        :
                                        <img
                                            className={classes.iconClassClose}
                                            src={iconUser} />}
                                </ListItem>
                            </Link>
                            <Link href="/sav">
                            <ListItem
                                button
                                key={t('NavBar.sav')}
                                className={classes.onglet}
                                onMouseEnter={()=> handleShowDash(true,t('NavBar.sav'), 4)}
                                onMouseLeave={() => handleShowDash(false,t('NavBar.sav'), 4)}>
                                {props.dataFromParent == "savPage" ?
                                    <img
                                        className={classes.iconClassClose}
                                        src={iconAssistSelected} />
                                    :
                                    <img
                                        className={classes.iconClassClose}
                                        src={iconAssist} />}
                            </ListItem>
                        </Link>
                        </div>
                        :
                        <div>
                            <Link href="/entreprises">
                                <ListItem
                                    button
                                    key={t('NavBar.ent')}
                                    className={classes.onglet}
                                    onMouseEnter={()=> handleShowDash(true,t('NavBar.ent'), 2)}
                                    onMouseLeave={() => handleShowDash(false,t('NavBar.ent'), 2)}>
                                    {props.dataFromParent === "entreprisePage" ?
                                        <img
                                            className={classes.iconClassClose}
                                            src={iconEntSelected} />
                                        :
                                        <img
                                            className={classes.iconClassClose}
                                            src={iconEnt} />
                                    }
                                </ListItem>
                            </Link>
                            <Link href="/sites">
                            <ListItem
                                button
                                key={t('NavBar.site')}
                                className={classes.onglet}
                                onMouseEnter={()=> handleShowDash(true,t('NavBar.site'), 3)}
                                onMouseLeave={() => handleShowDash(false,t('NavBar.site'), 3)}>
                                {props.dataFromParent === "sitePage" ?
                                    <img
                                        className={classes.iconClassClose}
                                        src={iconSiteSelected} />
                                    :
                                    <img
                                        className={classes.iconClassClose}
                                        src={iconSite} />}
                                </ListItem>
                            </Link>

                            <Link href="/users">
                                <ListItem
                                    button
                                    key={t('NavBar.user')}
                                    className={classes.onglet}
                                    onMouseEnter={()=> handleShowDash(true,t('NavBar.user'), 4)}
                                    onMouseLeave={() => handleShowDash(false,t('NavBar.user'), 4)}>
                                    {props.dataFromParent === "userPage" ?
                                        <img
                                            className={classes.iconClassClose}
                                            src={iconUserSelected} />
                                        :
                                        <img
                                            className={classes.iconClassClose}
                                            src={iconUser} />}
                                </ListItem>
                            </Link>
                            <Link href="/sav">
                                <ListItem
                                    button
                                    key={t('NavBar.sav')}
                                    className={classes.onglet}
                                    onMouseEnter={()=> handleShowDash(true,t('NavBar.sav'), 5)}
                                    onMouseLeave={() => handleShowDash(false,t('NavBar.sav'), 5)}>
                                    {props.dataFromParent == "savPage" ?
                                        <img
                                            className={classes.iconClassClose}
                                            src={iconAssistSelected} />
                                        :
                                        <img
                                            className={classes.iconClassClose}
                                            src={iconAssist} />}
                                </ListItem>
                            </Link>
                        </div>
                        }
                
                    {/* <Link href="/profil">
                        <ListItem
                            button
                            key={t('NavBar.profil')}
                            className={classes.onglet}>
                            {props.dataFromParent == "profilPage" ?
                                <img
                                    className={classes.iconClass}
                                    src={iconUserSelected} />
                                :
                                <img
                                    className={classes.iconClass}
                                    src={iconUser} />}
                        </ListItem>
                    </Link> */}
                </List>
                
            </Drawer>}

            {isShown && (
                            renderHover()
                )}
        </div>
        
    );

};

// NavBar.propTypes = {
//     classes: PropTypes.object.isRequired,
// };

export default NavBar;