import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import NavBar from "../Components/NavBar";
import axios from "../plugins/axios";
import cookie from "js-cookie";
import { MenuItem, Paper, Select } from "@material-ui/core";

import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import imgbtnClose from "../static/images/quitter.png";
// import imgbtnOpen from '../static/images/open.png'
import imgbtnSettings from "../static/images/settings.png";
import drapeauFr from "../static/images/002-france.png";
import drapeauEn from "../static/images/001-united-kingdom.png";
import logoutIcon from "../static/images/logout.png";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles({
  backgroundPage: {
    backgroundColor: "#F2F2F2",
    paddingBottom: "20px",
  },
  rootwNavOpen: {
    marginLeft: "260px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  rootwNavClose: {
    marginLeft: "70px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  // navOpen: {
  //   width: "260px"
  // },
  // navClose: {
  //   width:"70px"
  // },
  tableau: {
    borderRadius: "20px",
    marginRight: "40px",
    marginLeft: "10px",
    overflow: "auto",
    // minWidth: 1300,
  },
  entete: {
    width: "100%",
    height: "200px",
    display: "inline-flex",
  },
  volumeTotalDiv: {
    width: "319px",
    height: "108px",
    margin: "40px",
  },
  rightSideEntete: {
    width: "35%",
    // minWidth: "600px",
    // background: "red",
    marginRight: "40px",
    marginTop: "40px",
    marginLeft: "auto",
  },
  dateDiv: {
    marginTop: "0px",
    marginBottom: "20px",
    marginRight: "25px",
    textAlign: "right",
  },
  dateTxt: {
    fontSize: "25px",
    fontStyle: "Light 22px/27px Montserrat",
    color: "#6F6F8D",
  },
  selectBox: {
    width: "300px",
    position: "absolute",
    right: "10px",
  },
  formMail: {
    width: "600px",
    display: "-webkit-inline-box",
  },
  formulaire: {
    display: "inline-grid",
    position: "relative",
  },
  btnMail: {
    display: "block",
  },
  barreLatDroiteOpen: {
    width: "282px",
    flexShrink: 0,
  },
  barreLatDroiteClose: {
    width: "20px",
    flexShrink: 0,
  },
  barreLatDroiteHidden: {
    display: "none",
  },
  drawerPaper: {
    width: "60px",
  },
  drawerPaper2: {
    width: "282px",
  },
  userSideTxt: {
    writingMode: "vertical-lr",
    textOrientation: "mixed",
    transform: "rotate(180deg)",
    font: "Regular 18px/44px Montserrat",
    // width: "400px",
    marginLeft: "18px",
    position: "fixed",
    top: "80px",
  },
  btnOpenNavDroite: {
    //   margin: "10px",
    width: "60px",
    minWidth: "60px",
    alignSelf: "center",
  },
  btnCloseNavDroite: {
    width: "60px",
  },
  userNameBarLatOpen: {
    margin: "10px",
    font: "font: Regular 27px/44px Montserrat",
    marginTop: "50px",
  },
  typeUserBarLatOpen: {
    margin: "10px",
    font: "Medium 18px/44px Montserrat",
    color: "#5148B2",
  },
  EntrepriseBarLatOpen: {
    margin: "10px",
    font: "Light 18px/44px Montserrat",
    color: "#5148B2",
  },
  separatorRightBar: {
    margin: "50px",
  },
  langDiv: {
    // marginBottom: "50px"
  },
  btnLogout: {
    position: "fixed",
    width: "100%",
    maxWidth: "250px",
    bottom: "20px",
    marginRight: "20px",
    marginLeft: "20px",
    background: "#FFF",
  },
  selectUnitMes: {
    marginRight: "20px",
    marginLeft: "20px",
  },
  switchFilter: {
    alignSelf: "center",
    // height:"60px",
    // width: "100px"
  },
  labelRightBar: {
    font: "Light 15px/44px Montserrat",
    color: "#BEBEBE",
    marginBottom: "10px",
  },
  imgbtnset: {
    width: "60px",
  },
});

function SavPage(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  var axios_customUrl;
  if (process.env.NODE_ENV === "production") {
    axios_customUrl = "https://auth.piink-teknology.com";
  } else {
    // axios_customUrl = "http://192.168.1.13:3004".
    axios_customUrl = "https://auth.piink-teknology.com";
  }

  var token = cookie.get("token");
  var inf = cookie.get("infosUser");
  var infsplit = inf.split("__");

  const [identityUser, setIdentityUser] = React.useState({
    mail: "",
    name: "",
    firstname: "",
    role: "user",
    sites: [],
    ent: "",
    parts: [],
  });

  const [rightBarState, setRightBarState] = React.useState(false);
  const [isNavBarOpen, setIsNavBarOpen] = React.useState(true);
  const [termSelected, setTermSelected] = React.useState("");
  const [mailSubject, setMailSubject] = React.useState("");
  const [mailBody, setMailBody] = React.useState("");
  const [listTerm, setListTerm] = React.useState([]);
  const [nomSoc, setNomSoc] = React.useState("");
  const [siteTerm, setSiteTerm] = React.useState("");
  const [term, setTerm] = React.useState({
    terminalName: "",
  });
  const [checked, setChecked] = React.useState(true);

  //definition du swicth
  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: "#eb4034",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  useEffect(() => {
    async function fetchData() {
      var usersInfo = await axios.customAxios.get(
        axios.customAxios.defaults.baseURL + "/user/getByEmail",
        {
          params: {
            emailUser: infsplit[0],
          },
        }
      );

      if (
        !usersInfo.data.sitePartenaire.includes(
          usersInfo.data.entrepriseUser._id
        )
      ) {
        usersInfo.data.sitePartenaire.push(usersInfo.data.entrepriseUser._id);
      }

      setIdentityUser({
        mail: usersInfo.data.emailUser,
        name: usersInfo.data.nomUser,
        firstname: usersInfo.data.prenomUser,
        role: infsplit[1],
        sites: usersInfo.data.siteUser,
        ent: usersInfo.data.entrepriseUser.nomEntreprise,
        parts: usersInfo.data.sitePartenaire,
      });

      if (
        usersInfo.data.prefLang === "EN" &&
        usersInfo.data.prefLang !== null
      ) {
        changeLanguage("en");
      }

      var response;
      if (infsplit[1] === "adminPiink") {
        response = await axios.customAxios.get(
          axios.customAxios.defaults.baseURL + "/device",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: 10000,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        //then get Term/partenaire from partName
        response = await axios.customAxios.get(
          axios.customAxios.defaults.baseURL + "/device/getDevicesFromPart",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: 10000,
              listPartenaire: usersInfo.data.sitePartenaire,
            },
          }
        );
      } else {
        var listSiteUser = usersInfo.data.siteUser;
        console.log(listSiteUser);

        var listID = [];
        listSiteUser.forEach((elt) => {
          listID.push(elt._id);
        });

        response = await axios.customAxios.get(
          axios.customAxios.defaults.baseURL + "/device/getDeviceForUser",
          {
            params: {
              siteUser: listID,
              nbOfPage: 0,
              nbMeasureDisplay: 10000,
            },
          }
        );
        console.log(response.data);
      }
      setListTerm(response.data);
    }

    fetchData();
  }, []);

  const callBackNav = (navData) => {
    setIsNavBarOpen(navData);
  };

  const handleChangeObject = (event) => {
    setMailSubject(event.target.value);
  };

  async function handleChangeTerm(event) {
    setTermSelected(event.target.value);

    //update corp infos
    //get term infos
    console.log(event.target.value);
    var t = await axios.customAxios.get(
      axios.customAxios.defaults.baseURL + "/device/getFromMac",
      {
        params: {
          macAddress: event.target.value,
        },
      }
    );
    setTerm(t.data);
    console.log("t.data =>");
    console.log(t.data);

    //getSite infos
    var s = await axios.customAxios.get(
      axios.customAxios.defaults.baseURL + "/site/getFromId",
      {
        params: {
          id: t.data.siteDevice,
        },
      }
    );
    console.log(s.data);
    setSiteTerm(s.data.nomSite);
    setNomSoc(s.data.entrepriseSite.nomEntreprise);
  }

  const Mailto = ({ email, subject, body, children }) => {
    return (
      <a
        className={classes.btnMail}
        href={`mailto:${email}?subject=${
          encodeURIComponent(subject) || ""
        }&body=${encodeURIComponent(body) || ""}`}
      >
        {children}
      </a>
    );
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    //call update prefLang
    updateLng(lng);
  };

  async function updateLng(lng) {
    var langSelected;
    if (lng === "fr") {
      langSelected = "FR";
    } else if (lng === "en") {
      langSelected = "EN";
    }
    await axios.customAxios.post(
      axios.customAxios.defaults.baseURL + "/user/changeLng",
      {
        emailUser: identityUser.mail,
        prefLang: langSelected,
      }
    );
  }

  function renderOptionPartenaire() {
    return listTerm.map((part, i) => {
      return (
        <MenuItem
          label="part"
          value={part.macTerm}
          key={i}
          name={part.deviceName}
        >
          {part.deviceName} ({part.macTerm})
        </MenuItem>
      );
    });
  }

  function toggleRightBarPanel(valToChange) {
    setRightBarState(!valToChange);
  }

  const handleChangeCollapse = () => {
    setChecked((prev) => !prev);
  };

  var loc = "";
  async function logout() {
    cookie.remove("token");
    cookie.remove("infosUser");
    loc = axios_customUrl + "/logout?client_id=piink3dHub&token=" + token;
  }

  return (
    <div className={classes.backgroundPage}>
      <NavBar
        pageCallBack={callBackNav}
        dataFromParent={"savPage"}
        dataIsUser={identityUser.role}
      />
      <div
        className={isNavBarOpen ? classes.rootwNavOpen : classes.rootwNavClose}
      >
        <div className={classes.entete}>
          <div className={classes.volumeTotalDiv}>
            <h1>{t("SavPage.title")}</h1>
          </div>
          <div className={classes.rightSideEntete}>
            <div className={classes.dateDiv}>
              <span className={classes.dateTxt}>
                {identityUser.firstname} {identityUser.name} -{" "}
                {identityUser.ent}
              </span>
            </div>
          </div>
        </div>
        <Paper className={classes.tableau}>
          <h2>{t("SavPage.needHelp")} ?</h2>
          {/* 
                        selection du sujet SAV
                        selection de terminal
                        bouton envoie
                    */}
          <div className={classes.formulaire}>
            <div className={classes.formMail}>
              <h3>{t("SavPage.subject")} : *</h3>
              <Select
                id="selectSAVsubject"
                className={classes.selectBox}
                variant="outlined"
                value={mailSubject}
                onChange={handleChangeObject}
              >
                <MenuItem value={"Demande de support / SAV - Piink3dHub"}>
                  {t("SavPage.demSAV")}
                </MenuItem>
                <MenuItem value={"Renseignement commercial - Piink3dHub"}>
                  {t("SavPage.rensCom")}
                </MenuItem>
                <MenuItem value={"Autre - Piink3dHub"}>
                  {t("SavPage.other")}
                </MenuItem>
              </Select>
            </div>
            <div className={classes.formMail}>
              <h3>{t("SavPage.selectDev")} : </h3>
              <Select
                id="selectTerm"
                className={classes.selectBox}
                variant="outlined"
                value={termSelected}
                onChange={handleChangeTerm}
              >
                {renderOptionPartenaire()}
              </Select>
            </div>
          </div>

          {termSelected !== "" ? (
            <Mailto
              className={classes.btnMail}
              email={"support@piink-teknology.com"}
              subject={mailSubject}
              body={
                "Société : " +
                nomSoc +
                "\nSite : " +
                siteTerm +
                "\nNom terminal : " +
                term.deviceName +
                "\nMac terminal : " +
                termSelected +
                "\n"
              }
            >
              {t("SavPage.SendMail")}
            </Mailto>
          ) : (
            <Mailto
              className={classes.btnMail}
              email={"support@piink-teknology.com"}
              subject={mailSubject}
              body={""}
            >
              {t("SavPage.SendMail")}
            </Mailto>
          )}
        </Paper>
      </div>
      {rightBarState ? (
        <Drawer
          className={classes.barreLatDroiteOpen}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper2,
          }}
          anchor="right"
        >
          {/* logout */}
          <Button
            className={classes.btnCloseNavDroite}
            onClick={(e) => toggleRightBarPanel(true)}
          >
            {" "}
            <img src={imgbtnClose} alt="btnCLose" />{" "}
          </Button>
          v2.0.15
          <h2 className={classes.userNameBarLatOpen}>
            {identityUser.name} {identityUser.firstname}
          </h2>
          <span className={classes.typeUserBarLatOpen}>
            {identityUser.role}
          </span>
          <span className={classes.EntrepriseBarLatOpen}>
            {identityUser.ent}
          </span>
          <Divider className={classes.separatorRightBar} />
          <div className={classes.langDiv}>
            <IconButton onClick={() => changeLanguage("fr")}>
              <img src={drapeauFr} alt="drapeau FR" />
            </IconButton>
            <IconButton onClick={() => changeLanguage("en")}>
              <img src={drapeauEn} alt="drapeau EN" />
            </IconButton>
            {/* <Button onClick={() => changeLanguage('fr')}>icone drapeau fr</Button>
            <Button onClick={() => changeLanguage('en')}>en flag icon</Button> */}
          </div>
          <Divider className={classes.separatorRightBar} />
          <Button
            variant="contained"
            className={classes.btnLogout}
            endIcon={<img src={logoutIcon} alt="logout" />}
            onClick={() => logout()}
          >
            {t("Buttons.returnHub")}
          </Button>
          {/* <button onClick={e => toggleRightBarPanel(true)} className={classes.btnRightBar2} /> */}
        </Drawer>
      ) : (
        <Drawer
          className={classes.barreLatDroiteClose}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="right"
        >
          <Button
            className={classes.btnOpenNavDroite}
            onClick={(e) => toggleRightBarPanel(false)}
          >
            {" "}
            <img
              src={imgbtnSettings}
              alt="settings"
              className={classes.imgbtnset}
            />{" "}
          </Button>
          <span className={classes.userSideTxt}></span>
          {/* <button onClick={e => toggleRightBarPanel(false)} className={classes.btnRightBar} /> */}
        </Drawer>
      )}
    </div>
  );
}

export default SavPage;
