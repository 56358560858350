import React, { useState } from 'react'
// import { useAuth } from "../context/auth";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "js-cookie";
import axios from '../plugins/axios';
import qs from "qs"

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    root: {
        width: '100%',
        // marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
        width: "90%"
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        width: '70%'
    },
    divForm: {
        width: '100%',
        // margin: theme.spacing(3)
    },
    formGrp: {
        width: '80%'
    },
    divContainerLogin: {
        minWidth: 700,
        width: '30%',
        margin: 'auto',
        marginTop:"50px",
        // background: "red",
        padding: "20px",
        boxShadow: "5px 5px 5px 4px rgba(0, 0, 0, 0.2);",
        // border: "solid",
        borderRadius: "20px"
    },
    divBtns: {
        marginTop: "20px"
    }
});


function Login(props) {

    // //get this.props.cookies
    // const { cookies } = this.props;
    // //setting a cookie
    // cookies.set('name', 'Ross', { path: '/' });
    // //getting a cookie
    // cookies.get('name');

    // const cookies = new Cookies();
    // const [setCookie, removeCookie] = useCookies(['cookiesPiink3dHub']);


    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    const [isLoggedIn, setLoggedIn] = useState(false);
    const [log, setlog] = React.useState({
        identificator: "",
        password: ""
    })
    // const { authTokens, setAuthTokens } = useAuth();

    // // referer = recup des cookies -> si pas de cookies redirect vers '/'
    // const referer =  '/';

    // console.log(referer)
    // console.log(localStorage.getItem('tokens'))
    // console.log(authTokens)
    // if (localStorage.getItem('tokens')) {
    //     console.log("get the local storage")
    //     authTokens.setAuthTokens(localStorage.getItem('tokens'))
    // }

    function pressReset() {
        // setlog({
        //     identificator: "",
        //     password: ""
        // })
        // setAuthTokens()
    }

    async function pressLogin() {
        var loginRequestObj = {
            username: log.identificator,
            password: log.password
        }
        const config = {
            baseURL:"http://192.168.1.13:3004",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        try {
            // console.log(axios.defaults)
            var response = await axios.post('/login',
                qs.stringify(loginRequestObj),
                config
            )
            console.log("response of /login -> ")
            console.log(response)

            // //if reponse ok
            if(response.data != null){
                cookie.set("token", response.data.access_token);
                props.setLogin(response.data.user);
                setLoggedIn(true);
            }
            
            // props.history.push("/mesures");

        }catch(error){
            console.log("error : "+ error)
            return false;
        }

        // cookies.setCookie({
        //     name: log.identificator,
        //     role: "user",
        //     token: "tokenId"
        // })
        // setLoggedIn(true)
        // console.log(isLoggedIn)
        // console.log(cookies)

        // return <Redirect to="/mesure" push={true}/>;


        // try {
        //     var response = await axios.post(axios.defaults.baseURL + '/user/login',
        //         qs.stringify(loginRequestObj),
        //         config
        //     )
        //     console.log(response.data)
        // }catch(err){
        //     console.error(err)
        // }



        // setAuthTokens({
        //     name: log.identificator,
        //     role: "user",
        //     token: "tokenId"
        // })
        setLoggedIn(true);
    }

    const handleChangeFields = name => event => {
        setlog({ ...log, [name]: event.target.value });
    };
    // console.log(isLoggedIn)
    if (isLoggedIn) {
        return <div><Redirect to="/mesures" /></div>
    }

    return(
        <div>
            <div className={classes.divContainerLogin}>
                <h1 className={classes.titlePage}>{t('Login.title')}</h1>
                <div className={classes.btnLang}>
                    <Button onClick={() => changeLanguage('fr')}>fr</Button>
                    <Button onClick={() => changeLanguage('en')}>en</Button>
                </div>
                <div>
                    <TextField
                        margin="dense"
                        id="ident"
                        label={t('Login.EmailIdentification')}
                        className={classes.textField}
                        variant="outlined"
                        onChange={handleChangeFields("identificator")}
                        value={log.identificator}
                    />
                    <TextField
                        margin="dense"
                        id="pass"
                        label={t('Login.Password')}
                        className={classes.textField}
                        variant="outlined"
                        onChange={handleChangeFields("password")}
                        value={log.password}
                    />
                </div>
                <div className={classes.divBtns}>
                    <Button onClick={() => pressReset()}>{t('Login.Button.reset')}</Button>
                    <Button onClick={() => pressLogin()}>{t('Login.Button.login')}</Button>
                </div>

            </div>

        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        setLogin: user => dispatch({ type: "SET_LOGIN", payload: user })
    };
};
export default connect(
    null,
    mapDispatchToProps
)(Login);

// export default Login;