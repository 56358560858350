import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {

    // http://192.168.1.13:3004/oauth/login?client_id=piink3dHub
    var axios_customUrl
    if (process.env.NODE_ENV == 'production') {
        axios_customUrl = "https://auth.piink-teknology.com"
    } else {
        // axios_customUrl = "http://192.168.1.13:3004"
        axios_customUrl = "https://auth.piink-teknology.com"
    }

    return (
        <Route
            {...rest}
            render={props => 
                rest.loggedIn ? (
                //   true  ? (
                    <Component {...props} />
                ) : (
                        // <Redirect
                        //     to={{
                        //         pathname: "/",
                        //         state: { from: props.location }
                        //     }}
                        // />

                        <Route path='/' component = {() => {
                            window.location.href = axios_customUrl+'/oauth/authorize?client_id=piink3dHub&response_type=code&redirect_uri=/mesures';
                            return null;
                        }}/>
                )}
        />
    );
};

const mapStateToProps = state => {
    return {
        loggedIn: state.auth.loggedIn
    };
};

export default connect(mapStateToProps)(PrivateRoute);