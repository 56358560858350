import React, { useState, useEffect, useRef } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NavBar from "../Components/NavBar";
import { withStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
// import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import TablePagination from "@material-ui/core/TablePagination";
// import InputLabel from '@material-ui/core/InputLabel';
// import Checkbox from '@material-ui/core/Checkbox'
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import imgbtnClose from "../static/images/quitter.png";
// import imgbtnOpen from '../static/images/open.png'
import imgbtnSettings from "../static/images/settings.png";
import InputLabel from "@material-ui/core/InputLabel";

import { useTranslation } from "react-i18next";
// import { makeStyles } from '@material-ui/core/styles';
// import img from '../static/images/fond.png'
import imgDetail from "../static/images/details.png";
import imgSquareMes from "../static/images/square.png";
import imgSupp from "../static/images/supprimer.png";
import imgSuppDisable from "../static/images/Trash_grisé.png";
import imgWarningSupp from "../static/images/Icon-metro-warning.png";
import dwlPointCloud from "../static/images/Icon feather-download-cloud.png";
import imgResetFilters from "../static/images/Composant5–1.png";
import imgclosepop from "../static/images/Icon ionic-ios-close.png";
import bgVol from "../static/images/bg-volume.png";
import btnExpandeRight from "../static/images/quit.png";
import drapeauFr from "../static/images/002-france.png";
import drapeauEn from "../static/images/001-united-kingdom.png";
import logoutIcon from "../static/images/logout.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import icnDownload from "../static/images/Icon-feather-download.png";
import icnTrash from "../static/images/Icon ionic-md-trash.png";
// import btnCloseRight from '../static/images/quit2.png'
import { Route, Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Alert } from "@material-ui/lab";
// import DayPicker, { DateUtils } from 'react-day-picker';

// import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import ReactExport from "react-data-export";
import moment from "moment";

// import { useCookies } from 'react-cookie';
import cookie, { defaults } from "js-cookie";

// import { useAuth } from "../context/auth";

import axios from "../plugins/axios";
import { Checkbox, Select } from "@material-ui/core";

const useStyles = makeStyles({
  body: {
    display: "inline-flex",
    width: "100%",
  },
  rootwNavOpen: {
    width: "100%",
    marginLeft: "10px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  rootwNavClose: {
    width: "100%",
    marginLeft: "10px",
    marginRight: "40px",
    overflowX: "auto",
    // minWidth: 1300,
    backgroundColor: "#F2F2F2",
  },
  // navOpen: {
  //   width: "260px"
  // },
  // navClose: {
  //   width:"70px"
  // },
  table: {
    // minWidth: 1300,
    overflow: "auto",
    width: "100%",
  },
  tableau: {
    borderRadius: "20px",
    marginRight: "40px",
    marginLeft: "10px",
    overflow: "auto",
    // minWidth: 1300,
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: "100%",
  },
  divForm: {
    width: "100%",
    // margin: theme.spacing(3)
  },
  formGrp: {
    width: "80%",
  },
  dialogDetail: {
    width: "550px",
    height: "630px",
  },
  dialogLoadDetail: {
    width: "550px",
    height: "630px",
    textAlign: "center",
  },
  rowDone: {
    backgroundColor: "green",
    textAlign: "center",
  },
  divFilter: {
    width: "100%",
    margin: "10px",
  },
  formGrpFilters: {
    width: "100%",
    margin: "10px",
  },
  divFormFilters: {
    width: "100%",
    // margin: "10px",
    textAlign: "left",
  },
  textFieldFilter: {
    width: "22%",
    margin: "10px",
  },
  statefilter: {
    width: "22%",
    margin: "10px",
  },
  collapseFilter: {
    width: "100%",
  },
  inputHint: {
    marginLeft: "10px",
    marginTop: "-10px",
  },
  photoPopup: {
    // width: "250px",
    // height: "400px",
    // marginLeft: "10px"
  },
  imgDisplay: {
    maxWidth: "100%",
    maxHeight: "500px",
  },
  entete: {
    width: "100%",
    height: "200px",
    display: "inline-flex",
  },
  volumeTotalDiv: {
    width: "419px",
    height: "138px",
    backgroundColor: "#5148B2 ",
    borderRadius: "20px",
    margin: "40px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: "10px",
  },
  labelVol: {
    color: "#8A85CA",
    fontStyle: "Medium 40px/49px Montserrat",
    textAlign: "center",
    // display: "flex",
    // marginTop: "10px",
    // marginLeft: "173px"
  },
  entName: {
    textTransform: "uppercase",
  },
  displayNBTT: {
    color: "#8A85CA",
    fontStyle: "Medium 40px/49px Montserrat",
  },
  displayVol: {
    color: "#FFF",
    // marginLeft: "40px",
    margin: "15px",
    fontStyle: "Medium 40px/49px Montserrat",
    display: "-webkit-box",
  },
  rightSideEntete: {
    width: "35%",
    // minWidth: "700px",
    // background: "red",
    marginRight: "40px",
    marginTop: "40px",
    marginLeft: "auto",
  },
  dateDiv: {
    marginTop: "0px",
    marginBottom: "20px",
    marginRight: "25px",
    textAlign: "right",
  },
  dateTxt: {
    fontSize: "25px",
    fontStyle: "Light 22px/27px Montserrat",
    color: "#6F6F8D",
  },
  btnsReinit: {
    marginTop: "15px",
    textAlign: "end",
    display: "flex",
  },
  btnOneReinit: {
    backgroundColor: "#5148B2",
    color: "#FFF",
    margin: "10px",
    width: "200px",
    height: "50px",
    // "&:hover": {
    //   color: "#fff",
    //   background: "#5F2CB3",
    // },
  },
  btnTwoReinit: {
    backgroundColor: "#25C2F2",
    color: "#FFF",
    margin: "10px",
    width: "200px",
    height: "50px",
  },
  tableCell: {
    maxWidth: "350px",
    // overflow: "auto",
    textAlign: "center",
  },
  tableCellID: {
    maxWidth: "350px",
    // overflow: "auto",
    textAlign: "center",
    fontSize: "0.55vw",
  },
  filtrageRow: {
    backgroundColor: "#F2F2F2",
  },
  txtfield: {
    width: "100%",
    backgroundColor: "#FFF",
    height: "40px",
  },
  txtfieldD: {
    width: "100%",
    backgroundColor: "#FFF",
    marginTop: "0px",
    marginBottom: "0px",
  },
  txtfieldDate: {
    // position: "absolute",
    // height:"5px",
    zIndex: "1",
    // marginTop: "-8px",
    // marginLeft: "5px"
    // color: "#3f51b5"
  },
  txtfieldDPopup: {
    width: "100%",
    backgroundColor: "#F5F6FA",
    marginTop: "10px",
    marginBottom: "20px",
  },
  tableCellDate: {
    padding: "0px",
  },
  selectInfSup: {
    backgroundColor: "#FFF",
    height: "40px",
    marginTop: "8px",
    marginRight: "5px",
  },
  selectInfSupLabel: {
    backgroundColor: "#FFF",
    height: "40px",
    marginLeft: "5px",
    marginRight: "5px",
    verticalAlign: "middle",
  },
  barreLatDroiteOpen: {
    width: "282px",
    flexShrink: 0,
  },
  barreLatDroiteClose: {
    width: "20px",
    flexShrink: 0,
  },
  barreLatDroiteHidden: {
    display: "none",
  },
  drawerPaper: {
    width: "60px",
  },
  drawerPaper2: {
    width: "282px",
  },
  userSideTxt: {
    writingMode: "vertical-lr",
    textOrientation: "mixed",
    transform: "rotate(180deg)",
    font: "Regular 18px/44px Montserrat",
    // width: "400px",
    marginLeft: "18px",
    position: "fixed",
    top: "80px",
  },
  btnRightBar: {
    backgroundImage: `url(${btnExpandeRight})`,
    marginLeft: "15px",
    position: "fixed",
    bottom: "50px",
    backgroundSize: "contain",
    height: "23px",
  },
  btnRightBar2: {
    // backgroundImage: `url(${btnCloseRight})`,
    // marginLeft: "15px",
    position: "fixed",
    bottom: "50px",
    right: "10px",
    margin: "10px",
    marginTop: "30px",
    background: "#FFF",
    // backgroundSize: "contain",
    // height: "23px"
  },
  btnOpenNavDroite: {
    // margin: "10px",
    width: "60px",
    minWidth: "60px",
    alignSelf: "center",
  },
  btnCloseNavDroite: {
    width: "60px",
  },
  userNameBarLatOpen: {
    margin: "10px",
    font: "font: Regular 27px/44px Montserrat",
    marginTop: "50px",
  },
  typeUserBarLatOpen: {
    margin: "10px",
    font: "Medium 18px/44px Montserrat",
    color: "#5148B2",
  },
  EntrepriseBarLatOpen: {
    margin: "10px",
    font: "Light 18px/44px Montserrat",
    color: "#5148B2",
  },
  separatorRightBar: {
    margin: "50px",
  },
  separatorPopup: {
    marginBottom: "30px",
  },
  separatorPopupDetail: {
    marginBottom: "20px",
  },
  separatorPopupTxt: {
    marginBottom: "7px",
    marginTop: "7px",
  },
  langDiv: {
    // marginBottom: "50px"
  },
  btnLogout: {
    position: "fixed",
    width: "100%",
    maxWidth: "250px",
    bottom: "20px",
    marginRight: "20px",
    marginLeft: "20px",
    background: "#FFF",
  },
  selectUnitMes: {
    marginRight: "20px",
    marginLeft: "20px",
  },
  switchFilter: {
    alignSelf: "center",
    // height:"60px",
    // width: "100px"
  },
  labelRightBar: {
    font: "Light 15px/44px Montserrat",
    color: "#BEBEBE",
    marginBottom: "10px",
  },
  imgbtnset: {
    width: "60px",
  },
  btnexport: {
    display: "none",
  },
  rowTable: {
    // "&:selected": {
    backgroundColor: "#39BEEF1A !important",
    // },
  },
  checkboxRow: {
    // color: '#39BEEF1A',
    "&$checked": {
      color: "#39BEEF1A !important",
    },
  },
  selectVolumeTT: {
    color: "white",
    marginLeft: "5px",
  },
  selectVolumeTT: {
    color: "white",
    marginLeft: "5px",
  },
  selectVol: {
    marginLeft: "5px",
  },
  square: {
    marginRight: "10px",
    marginLeft: "10px",
  },
  volTTandSELECT: {
    width: "340px",
  },
  btnNext: {
    float: "left",
    margin: "20px",
    marginBottom: "10px",
    display: "inline",
    fontSize: "23px",
  },
  btnsRight: {
    float: "right",
  },
  btnsRight2: {
    float: "right",
    width: "30px",
    marginRight: "35px",
    marginTop: "25px",
  },
  btnclosePop: {
    width: "20px",
    height: "20px",
  },
  btnPrev2: {
    margin: "15px",
    marginBottom: "10px",
    fontSize: "25px",
    borderRadius: "50%",
    color: "#fff",
    background: "#8049F4",
    transform: "rotate(180deg)",
    paddingTop: "12px",
    "&:hover": {
      color: "#fff",
      background: "#8E5DF5",
    },
  },
  btnPrev1: {
    margin: "15px",
    marginBottom: "10px",
    fontSize: "25px",
    borderRadius: "50%",
    color: "#fff",
    background: "#8049F4",
    paddingTop: "12px",
    "&:hover": {
      color: "#fff",
      background: "#8E5DF5",
    },
  },
  btnPrev1Disable: {
    margin: "15px",
    marginBottom: "10px",
    fontSize: "25px",
    borderRadius: "50%",
    color: "#fff",
    background: "#F5F6FA",
    paddingTop: "12px",
  },
  btnPrev2Disable: {
    margin: "15px",
    marginBottom: "10px",
    fontSize: "25px",
    borderRadius: "50%",
    color: "#fff",
    background: "#F5F6FA",
    paddingTop: "12px",
    transform: "rotate(180deg)",
  },
  blockSpace: {
    width: "30px",
    marginRight: "158px",
  },
  rowSelected: {
    backgroundColor: "#91d0ff !important",
  },
  textFieldMes: {
    width: "30%",
    margin: "5px",
  },
  alltextFieldMes: {
    marginBottom: "15px",
  },
  footerPop: {
    position: "relative",
  },
  btnPopSupp: {
    position: "absolute",
    left: "5px",
  },
  volumeLabel: {
    verticalAlign: "middle",
    display: "inline",
  },
  volumeTableCell: {
    textAlign: "center",
  },
  btnPopupExport: {
    backgroundColor: "#8049F4",
    color: "#FFF",
    margin: "auto",
    width: "95%",
    height: "50px",
    marginTop: "20px",
    marginBottom: "20px",
    fontSize: "18px",
  },
  btnPopupSupp1: {
    backgroundColor: "#EFF0F4",
    color: "#FD7B8B",
    margin: "auto",
    width: "90%",
    height: "50px",
    // marginTop: "20px",
    margin: "15px",
    fontSize: "18px",
  },
  btnPopupSupp2: {
    backgroundColor: "#8049F4",
    color: "#FFF",
    margin: "auto",
    width: "95%",
    height: "50px",
    // marginTop: "20px",
    margin: "15px",
    fontSize: "18px",
  },
  titleExport: {
    fontSize: "25px",
  },
  imgWarningSupp: {
    textAlign: "center",
    marginBottom: "20px",
  },
  titleSupp: {
    color: "#8049F4",
    fontSize: "40px",
    textAlign: "center",
    marginBottom: "20px",
  },
  txtPopupSupp: {
    textAlign: "center",
    fontSize: "17px",
    marginBottom: "20px",
  },
  PopupSupp: {
    width: "500px",
  },
  txtPopDetail: {
    fontWeight: "bold",
    marginBottom: "5px",
    fontSize: "13px",
  },
  txtPopDetail2: {
    fontSize: "13px",
    fontWeight: "normal",
  },
  bottomPopupDetail: {
    marginTop: "30px",
  },
  lineDownoadPointCloud: {
    display: "inline-flex",
    width: "100%",
    marginBottom: "20px",
  },
  btnDownloadPC: {
    width: "50%",
    background: "content-box",
    border: "2px solid #8049F4",
    color: "#8049F4",
    fontWeight: "bold",
  },
  divFormState: {
    // width: "50%",
    marginLeft: "auto",
    marginRight: "0px",
  },
  msgError: {
    margin: "10px",
  },
  textFieldComm: {
    background: "#F5F6FA",
    width: "100%",
    marginBottom: "20px",
  },
  icnDwl: {
    width: "20px",
    height: "20px",
    marginLeft: "10px",
  },
});

function MeasureTable(props) {
  // console.log(props)

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const buttonExportRef = useRef();
  var axios_customUrl;
  if (process.env.NODE_ENV === "production") {
    axios_customUrl = "https://auth.piink-teknology.com";
  } else {
    // axios_customUrl = "http://192.168.1.13:3004"
    axios_customUrl = "https://auth.piink-teknology.com";
  }
  // const [cookies, setCookie, removeCookie] = useCookies(['cookie-user']);

  const [measures, setMeasures] = useState([]);
  const [exportExcel, setExportExcel] = useState([]);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openLoad, setOpenLoad] = React.useState(false);
  const [state, setStateBtn] = React.useState({
    btnState: false,
  });
  const [stateForm, setStateForm] = React.useState({
    comm: "",
    photo: "",
    photoAr: "",
    SiteMes: "",
    lengthManuel: "",
    widthManuel: "",
    heightManuel: "",
    dist1: "",
    dist2: "",
    angle1: "",
    angle2: "",
    baseMac: "",
  });
  const [measureDetail, setDetail] = useState([]);
  const [checked, setChecked] = React.useState(true);
  const [stateFormFilter, setStateFormFilter] = React.useState({
    id: "",
    id1: "",
    id2: "",
    long: "",
    larg: "",
    haut: "",
    pds: "",
    date: "",
    date2: "",
    term: "",
    type: "",
    exp: "",
    des: "",
    vol: "",
    siteMes: "",
  });
  const [stateFilter, setStateFilter] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [pagination, setPagination] = React.useState({
    entriesLength: 0,
  });
  const [site, setSite] = React.useState({
    listSite: [],
    siteSelected: "",
  });

  const classes = useStyles();
  const [volumeTotal, setVolumeTotal] = React.useState(0);
  // const inputLabel = React.useRef(null);
  const [filterOn, setFilterOnOff] = React.useState(false);
  const [tabRowChecked, setTabRowChecked] = React.useState([]);
  const [rowToSupp, setRowToSupp] = React.useState([]);
  const [volTTChecked, setVolTTChecked] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [supOrInfVar, setSupOfInfVar] = React.useState(0);
  const [rightBarState, setRightBarState] = React.useState(false);
  const [identityUser, setIdentityUser] = React.useState({
    mail: "",
    name: "",
    firstname: "",
    role: "user",
    sites: [],
    ent: "",
    partName: "",
    langue: "",
    parts: [],
  });

  const [unitMesure, setUnitMesure] = React.useState("cm");
  // const [lockSelectRow, setlockSelectRow] = React.useState(false)
  const [selectedRow, setSelectedRow] = React.useState("");

  const [isNavBarOpen, setIsNavBarOpen] = React.useState(true);

  const [firstCall, setFirstCall] = React.useState(true);

  const [isDataCharging, setIsDataCharging] = React.useState(false);

  const [unitVol, setUnitVol] = React.useState("M");
  const [unitVolTT, setUnitVolTT] = React.useState("M");
  const [startDate, setStartDate] = React.useState("");

  const source = axios.defaultAxios.CancelToken.source();

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const [openMsgErrorMsgNoCP, setOpenMsgErrorMsgNoCP] = React.useState(false);
  const [openMsgErrorDateExp, setOpenMsgErrorDateExp] = React.useState(false);

  const [sitesField, setSitesField] = React.useState({
    listSite: [],
    siteSelected: "",
  });

  const [exportDev, setExportDev] = React.useState({
    nbPhotos: "",
  });
  const [exportMultiMode, setExportMultiMode] = React.useState({
    mode: "",
  });
  const [siteToExport, setSiteToExport] = React.useState("");
  const [openExport, setOpenExport] = React.useState(false);
  const [openExport2, setOpenExport2] = React.useState(false);
  const [openExportDev, setOpenExportDev] = React.useState(false);
  const [dateExport, setDateExport] = React.useState({
    dateD: "",
    dateF: "",
  });
  const handleChangedateDexport = (name) => (event) => {
    setDateExport({ ...dateExport, [name]: event.target.value });
  };
  const [stateSuppMultiple, setStateSuppMultiple] = React.useState(true);
  const [openMultipleSuppPopup, setOpenMultipleSuppPopup] =
    React.useState(false);
  const [openSingleSuppPopup, setOpenSingleSuppPopup] = React.useState(false);

  const [nextDisabled, setNextDisabled] = React.useState(false);
  const [prevDisabled, setPrevDisabled] = React.useState(false);
  const handleChangeTab = (event, newValue) => {
    // console.log(newValue)
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // const monthFr = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"]
  // const monthEn = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  const callBackNav = (navData) => {
    // console.log(navData)
    setIsNavBarOpen(navData);
  };

  const handleChangeSupOrInf = (val) => (event) => {
    setSupOfInfVar(event.target.value);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    //call update prefLang
    updateLng(lng);
  };

  const handleChangeSite = (value) => {
    // console.log(value)
    setStateForm({ ...stateForm, siteMes: value });
  };

  const handleChangeToExport = (value) => {
    setSiteToExport(value);
  };

  const handleChangeMode = (name) => (event) => {
    setExportMultiMode({ ...exportMultiMode, [name]: event.target.value });
  };

  const handleChangenbPhoto = (name) => (event) => {
    setExportDev({ ...exportDev, [name]: event.target.value });
  };

  async function updateLng(lng) {
    var langSelected;
    if (lng === "fr") {
      langSelected = "FR";
    } else if (lng === "en") {
      langSelected = "EN";
    }

    await axios.customAxios.post(
      axios.customAxios.defaults.baseURL + "/user/changeLng",
      {
        emailUser: identityUser.mail,
        prefLang: langSelected,
      }
    );
  }

  //definition du swicth

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: "#eb4034",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <span
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </span>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  var token = cookie.get("token");
  var inf = cookie.get("infosUser");
  var infsplit = inf.split("__");

  // const { authTokens, setAuthTokens } = useAuth();

  // console.log("authTokens on mesure : ")
  // console.log(authTokens)
  var loc = "";
  async function logout() {
    // console.log(inf)
    // var wiioauthTok = cookie.get("wiioauth")
    // console.log(token)
    // console.log(wiioauthTok)
    cookie.remove("token");
    cookie.remove("infosUser");
    loc = axios_customUrl + "/logout?client_id=piink3dHub&token=" + token;
    window.location = loc;
    // loc = axios.customAxios
    // console.log(loc)

    // cookie.remove("token")
    // cookie.remove("infosUser")

    // //get url of piinkHub
    // loc = await axios.customAxios.get(axios.customAxios.defaults.baseURL + "/app/getUrlPiinkHub")
    // console.log(loc.data.urlPiinkHub)
    // window.location = loc.data.urlPiinkHub
  }

  useEffect(() => {
    // let source = axios.defaultAxios.CancelToken.source();
    // console.log("source =>")
    // console.log(source)

    if (
      // (stateFormFilter.date !== "" && stateFormFilter.date2 !== "")
      // || (stateFormFilter.date === "" && stateFormFilter.date2 === "" )
      // &&
      stateFormFilter.id ||
      stateFormFilter.id1 ||
      stateFormFilter.id2 ||
      stateFormFilter.long ||
      stateFormFilter.larg ||
      stateFormFilter.haut ||
      stateFormFilter.pds ||
      stateFormFilter.term ||
      stateFormFilter.type ||
      stateFormFilter.exp ||
      stateFormFilter.des ||
      stateFormFilter.siteMes ||
      stateFormFilter.vol ||
      stateFormFilter.date ||
      stateFormFilter.date2
    ) {
      //si date et date2 alors test que date < date2
      // console.log(stateFormFilter.date)
      // console.log(new Date(stateFormFilter.date))
      console.log("call filter from useEffect");
      // callFilter()

      const LoadData = async () => {
        var paramsFilter = await buildParamFilter();
        setIsDataCharging(true);
        try {
          var response = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/measureFilter",
            {
              params: paramsFilter,
              cancelToken: source.token,
            }
          );
          var sitesID = [];
          identityUser.sites.forEach((elt) => {
            sitesID.push(elt._id);
          });

          setTabRowChecked([]);
          paramsFilter.unitVolume = unitVolTT;
          console.log("call calculVolumeTotal from loadData");
          calculVolumeTotal(
            true,
            paramsFilter,
            sitesID,
            unitVolTT,
            identityUser.role,
            identityUser.parts
          );
          console.log(response.data);
          setFilterOnOff(true);
          setMeasures(response.data);

          var response3 = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/countFilter",
            {
              params: paramsFilter,
              cancelToken: source.token,
            }
          );
          setPagination({ entriesLength: response3.data });
        } catch (error) {
          if (axios.defaultAxios.isCancel(error)) {
            console.log("caught cancel");
            // console.log(source)
          } else {
            throw error;
          }
        }
        setIsDataCharging(false);
      };

      LoadData();
      return () => {
        // console.log("unmounting")
        source.cancel("source cancel after new call");
        // console.log(source)
        // console.log("source canceled")
      };
    } else {
      //  console.log("test Filter")
      setStateFilter("");
      setTabRowChecked([]);
      setPage(0);
      setFilterOnOff(false);
      callSimple();
    }
  }, [stateFormFilter]);

  useEffect(() => {
    const source = axios.defaultAxios.CancelToken.source();
    async function fetchData() {
      // console.log("inf : ")
      // console.log(inf)
      setIsDataCharging(true);
      var response = {};
      var response3 = {};

      // console.log("inf.role : ")
      // console.log(infsplit[0])

      //get user info from cookie + mettre a jour info utilisateur
      // getByEmail
      var usersInfo = await axios.customAxios.get(
        axios.customAxios.defaults.baseURL + "/user/getByEmail",
        {
          params: {
            emailUser: infsplit[0],
          },
        }
      );

      console.log(usersInfo.data);

      if (
        !usersInfo.data.sitePartenaire.includes(
          usersInfo.data.entrepriseUser._id
        )
      ) {
        usersInfo.data.sitePartenaire.push(usersInfo.data.entrepriseUser._id);
      }

      setIdentityUser({
        mail: usersInfo.data.emailUser,
        name: usersInfo.data.nomUser,
        firstname: usersInfo.data.prenomUser,
        role: infsplit[1],
        sites: usersInfo.data.siteUser,
        ent: usersInfo.data.entrepriseUser.nomEntreprise,
        langue: usersInfo.data.prefLang,
        parts: usersInfo.data.sitePartenaire,
      });
      console.log(usersInfo.data);

      var unitMesToDisplay = "cm";
      // console.log(usersInfo.data.prefUnitMes)
      if (
        usersInfo.data.prefUnitMes !== "metric" &&
        usersInfo.data.prefUnitMes !== null
      ) {
        setUnitMesure("in");
        setUnitVol("FT");
        setUnitVolTT("FT");
        unitMesToDisplay = "in";
      }

      if (
        usersInfo.data.prefLang === "EN" &&
        usersInfo.data.prefLang !== null
      ) {
        changeLanguage("en");
      }

      // console.log("usersInfo.data -> ")
      // console.log(usersInfo.data)

      // console.log(infsplit[1])
      // console.log(unitMesure)
      console.log(unitMesToDisplay);
      var d;
      if (
        window.location.search.includes("filterTerm") ||
        window.location.search.includes("filterSite")
      ) {
        var filterbase = window.location.search.split("=");
        // var filterbasefinal = ""
        while (filterbase[1].includes("%20")) {
          filterbase[1] = filterbase[1].replace("%20", " ");
        }
        var filterbasefinal = filterbase[1];
        console.log(filterbasefinal);
        if (window.location.search.includes("filterTerm")) {
          setStateFormFilter({ term: filterbasefinal });
        } else {
          setStateFormFilter({ siteMes: filterbasefinal });
        }
      } else {
        if (infsplit[1] === "adminPiink") {
          // console.log("call EVERYTHING")

          response = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure",
            {
              params: {
                nbOfPage: page,
                nbMeasureDisplay: rowsPerPage + 1,
                unitMsr: unitMesToDisplay,
              },
            }
          );

          console.log(response.data);

          response3 = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/count"
          );
          setPagination({ entriesLength: response3.data });

          var responseSite = await axios.customAxios.get(
            axios.customAxios.defaults.baseURL + "/site"
          );
          setSite({ listSite: responseSite.data });
          setSitesField({ listSite: responseSite.data });

          d = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/getStartDateMes"
          );
        } else if (infsplit[1] === "partenaire") {
          // console.log(assocPart.data.partenaireName)
          //then get Measure from partName
          response = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL +
              "/measure/getMeasuresForPartenaire",
            {
              params: {
                listPartenaire: usersInfo.data.sitePartenaire,
                nbOfPage: page,
                nbMeasureDisplay: rowsPerPage + 1,
                unitMsr: unitMesToDisplay,
              },
            }
          );
          console.log(response.data);

          response3 = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/count",
            {
              params: {
                listPartenaire: usersInfo.data.sitePartenaire,
              },
            }
          );
          setPagination({ entriesLength: response3.data });

          var responseSite = await axios.customAxios.get(
            axios.customAxios.defaults.baseURL + "/site/getSitesFromPart",
            {
              params: {
                listPartenaire: usersInfo.data.sitePartenaire,
              },
            }
          );
          setSite({ listSite: responseSite.data });
          setSitesField({ listSite: responseSite.data });

          d = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/getStartDateMes",
            {
              params: {
                listPartenaire: usersInfo.data.sitePartenaire,
              },
            }
          );
        } else {
          console.log("call USER");

          var listSiteUser = usersInfo.data.siteUser;

          var listID = [];
          listSiteUser.forEach((elt) => {
            listID.push(elt._id);
          });

          // console.log(listSiteUser)
          //get mesures
          response = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/getMeasuresForUser",
            {
              params: {
                siteUser: listID,
                nbOfPage: page,
                nbMeasureDisplay: rowsPerPage + 1,
                unitMsr: unitMesToDisplay,
              },
            }
          );
          console.log(response.data);

          response3 = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/count",
            {
              params: {
                siteUser: listID,
              },
            }
          );
          console.log(response3.data);
          setPagination({ entriesLength: response3.data });

          var responseSite = await axios.customAxios.get(
            axios.customAxios.defaults.baseURL + "/site/getSitesFromNameSite",
            {
              params: {
                siteUser: listID,
              },
            }
          );
          setSite({ listSite: responseSite.data });
          setSitesField({ listSite: responseSite.sites });

          d = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/getStartDateMes",
            {
              params: {
                siteUser: listID,
              },
            }
          );

          // console.log(response)
        }

        // console.log("rowsPerPage : "+(rowsPerPage + 1))
        var measures = response.data;
        // console.log(measures)

        console.log(d.data);
        // console.log(identityUser)
        if (usersInfo.data.prefLang === "FR") {
          var dateD = moment(d.data).format("DD/MM/YYYY");
        } else {
          var dateD = moment(d.data).format("MM/DD/YYYY");
        }

        setStartDate(dateD);
        setMeasures(measures);

        // console.log(measures)

        // console.log(pagination.entriesLength)
        var uMVolTT = "";
        if (unitMesToDisplay === "cm") {
          uMVolTT = "M";
        } else {
          uMVolTT = "FT";
        }
        console.log("call calculVolumeTotal from fetchData");

        var listSiteUser = usersInfo.data.siteUser;

        var listID = [];
        listSiteUser.forEach((elt) => {
          listID.push(elt._id);
        });

        calculVolumeTotal(
          false,
          null,
          listID,
          uMVolTT,
          infsplit[1],
          usersInfo.data.sitePartenaire
        );
        setFirstCall(false);
        // console.log(measures)
      }

      setIsDataCharging(false);
    }

    fetchData();

    return () => {
      // console.log("unmounting")
      source.cancel("source cancel after new call");
      // console.log(source)
      // console.log("source canceled")
    };
  }, []);

  // const handleChangeStateFilter = event => {
  //   setStateFilter(event.target.value);
  // };

  const handleChangeCollapse = () => {
    setChecked((prev) => !prev);
  };

  const handleChange = (name) => (event) => {
    console.log("change ");
    setStateBtn({ ...state, [name]: event.target.checked });
    setStateForm({ ...stateForm, [name]: event.target.value });
  };

  const handleChangeFilter = (name) => (event) => {
    setStateFormFilter({ ...stateFormFilter, [name]: event.target.value });
    // console.log(stateFormFilter)
    //wait change then -> callFilter()
  };

  const handleChangeSiteMesFilter = (value) => {
    // console.log("handleChangeSiteMesFilter")
    // console.log(stateFormFilter.siteMes)
    // console.log(sitesField.listSite)
    console.log(value);
    if (value !== "" && value !== null) {
      var valueF = value.split(" - ");
      setStateFormFilter({ ...stateFormFilter, siteMes: valueF[1] });
    } else {
      setStateFormFilter({ ...stateFormFilter, siteMes: value });
    }
  };

  const handleChangeUnitMes = (name) => (event) => {
    setUnitMesure(event.target.value);

    //call update prefUnit
    updateUnit(event.target.value);

    callRefresh(event.target.value);
  };

  const handleChangeUnitVolume = (val) => (event) => {
    setUnitVol(event.target.value);
    callFilter(event.target.value);
    // console.log(event.target.value)
  };

  const handleChangeUnitVolumeTT = (val) => (event) => {
    setUnitVolTT(event.target.value);
    var sitesID = [];
    //then call calculVolTT avec la nouvelle unitmesure
    // if filtercall ?
    console.log("call calculVolumeTotal from handlechageunitvolume");
    if (filterOn) {
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);

      paramsFilter.state = stateFilter;
      paramsFilter.nbOfPage = page;
      paramsFilter.nbMeasureDisplay = rowsPerPage;
      paramsFilter.unitMsr = unitMesure;
      paramsFilter.unitVolume = event.target.value;

      if (infsplit[1] === "user") {
        identityUser.sites.forEach((elt) => {
          sitesID.push(elt._id);
        });
        // console.log("sitesID")
        // console.log(sitesID)
        paramsFilter.siteUser = sitesID;
      } else if (infsplit[1] === "partenaire") {
        paramsFilter.listPartenaire = identityUser.parts;
      }

      calculVolumeTotal(
        true,
        paramsFilter,
        sitesID,
        event.target.value,
        identityUser.role,
        identityUser.parts
      );
    } else {
      calculVolumeTotal(
        false,
        null,
        sitesID,
        event.target.value,
        identityUser.role,
        identityUser.parts
      );
    }
    setTabRowChecked([]);
    setVolTTChecked(0);
    setSelected([]);
    // calculVolumeTotal(,,, event.target.value)
  };

  async function updateUnit(unit) {
    var unitSelected;
    if (unit === "cm") {
      unitSelected = "metric";
    } else if (unit === "in") {
      unitSelected = "imperial";
    }

    await axios.customAxios.post(
      axios.customAxios.defaults.baseURL + "/user/changeUnit",
      {
        emailUser: identityUser.mail,
        prefUnitMes: unitSelected,
      }
    );
  }

  async function handleChangePage(event, newPage) {
    setIsDataCharging(true);
    // console.log("CHANGEPAGE")
    setPage(newPage);
    // console.log("filterOn")
    // console.log(filterOn)
    var response = {};
    if (filterOn) {
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);

      paramsFilter.state = stateFilter;
      paramsFilter.nbOfPage = newPage;
      paramsFilter.nbMeasureDisplay = rowsPerPage + 1;
      paramsFilter.unitMsr = unitMesure;
      paramsFilter.unitVolume = unitVol;
      // console.log(paramsFilter)

      // console.log(identityUser)
      if (infsplit[1] === "user") {
        var sitesID = [];
        identityUser.sites.forEach((elt) => {
          sitesID.push(elt._id);
        });
        // console.log("sitesID")
        // console.log(sitesID)
        paramsFilter.siteUser = sitesID;
      } else if (infsplit[1] === "partenaire") {
        paramsFilter.listPartenaire = identityUser.parts;
      }

      // console.log(paramsFilter)

      response = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/measureFilter",
        {
          params: paramsFilter,
        }
      );
      setMeasures(response.data);

      // var response3 = await axios.defaultAxios.get(axios.defaultAxios.defaults.baseURL + "/measure/countFilter", {
      //   params: paramsFilter
      // })
      // setPagination({ entriesLength: response3.data })
    } else {
      // console.log(infsplit[1])
      // console.log(infsplit[1] == "adminPiink")
      if (infsplit[1] === "adminPiink") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure",
          {
            params: {
              nbOfPage: newPage,
              nbMeasureDisplay: rowsPerPage + 1,
              unitMsr: unitMesure,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL +
            "/measure/getMeasuresForPartenaire",
          {
            params: {
              listPartenaire: identityUser.parts,
              nbOfPage: newPage,
              nbMeasureDisplay: rowsPerPage + 1,
              unitMsr: unitMesure,
            },
          }
        );
      } else {
        var sitesID = [];
        identityUser.sites.forEach((elt) => {
          sitesID.push(elt._id);
        });
        // console.log("listSiteUser")
        // console.log(listSiteUser)
        //get mesures
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getMeasuresForUser",
          {
            params: {
              siteUser: sitesID,
              nbOfPage: newPage,
              nbMeasureDisplay: rowsPerPage + 1,
              unitMsr: unitMesure,
            },
          }
        );
      }

      var measures = response.data;
      // console.log("measures : ")
      // console.log(measures)
      setMeasures(measures);
    }
    setIsDataCharging(false);
  }

  async function handleChangeRowsPerPage(event, newRowPerPage) {
    // console.log(newRowPerPage.props.value)
    setIsDataCharging(true);
    setRowsPerPage(newRowPerPage.props.value);
    setPage(0);

    console.log(identityUser);
    var response = {};
    if (filterOn) {
      // console.log("filt on")
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);

      paramsFilter.state = stateFilter;
      paramsFilter.nbOfPage = 0;
      paramsFilter.nbMeasureDisplay = newRowPerPage.props.value + 1;
      paramsFilter.unitMsr = unitMesure;
      paramsFilter.unitVolume = unitVol;

      if (infsplit[1] === "user") {
        var sitesID = [];
        identityUser.sites.forEach((elt) => {
          sitesID.push(elt._id);
        });
        // console.log("sitesID")
        // console.log(sitesID)
        paramsFilter.siteUser = sitesID;
      } else if (infsplit[1] === "partenaire") {
        paramsFilter.listPartenaire = identityUser.parts;
      }

      // console.log(paramsFilter)

      response = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/measureFilter",
        {
          params: paramsFilter,
        }
      );
      setMeasures(response.data);
    } else {
      // console.log("filt NOT on")
      if (infsplit[1] === "adminPiink") {
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: newRowPerPage.props.value + 1,
              unitMsr: unitMesure,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        //then get Measure from partName
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL +
            "/measure/getMeasuresForPartenaire",
          {
            params: {
              listPartenaire: identityUser.parts,
              nbOfPage: 0,
              nbMeasureDisplay: newRowPerPage.props.value + 1,
              unitMsr: unitMesure,
            },
          }
        );
      } else {
        var sitesID = [];
        identityUser.sites.forEach((elt) => {
          sitesID.push(elt._id);
        });
        // console.log(listSiteUser)
        //get mesures
        response = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getMeasuresForUser",
          {
            params: {
              siteUser: sitesID,
              nbOfPage: 0,
              nbMeasureDisplay: newRowPerPage.props.value + 1,
              unitMsr: unitMesure,
            },
          }
        );
      }

      // var response = await axios.defaultAxios.get(axios.defaultAxios.defaults.baseURL + '/measure', {
      //   params: {
      //     nbOfPage: 0,
      //     nbMeasureDisplay: newRowPerPage.props.value+1
      //   }

      // })

      var measures = response.data;
      setMeasures(measures);
    }
    //a voir comment gérer
    // calculVolumeTotal(measures)
    setIsDataCharging(false);

    // call measure table with updated params
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseExport = () => {
    setDateExport({ dateD: "" });
    setDateExport({ dateF: "" });
    setOpenExport(false);
  };

  const handleCloseExport2 = () => {
    setDateExport({ dateD: "" });
    setDateExport({ dateF: "" });
    setSiteToExport("");
    setOpenExport2(false);
  };

  const handleCloseExportDev = () => {
    setOpenExportDev(false);
  };

  async function handleValidate() {
    console.log("measureDetail =>");
    console.log(measureDetail);
    // console.log(state.btnState)
    // console.log(stateForm.comm)
    // console.log(stateForm.siteMes)
    //get id of siteMes
    var s = await axios.customAxios.get(
      axios.customAxios.defaults.baseURL + "/site/getIdSiteFromName",
      {
        params: {
          siteToSearch: stateForm.siteMes,
        },
      }
    );

    var idToSend = s.data;
    console.log(idToSend);

    await axios.defaultAxios.post(
      axios.defaultAxios.defaults.baseURL + "/measure",
      {
        idUnique: measureDetail.idUnique,
        state: state.btnState,
        commentaire: stateForm.comm,
        siteMeasure: idToSend,
        lengthManuel: stateForm.lengthManuel,
        widthManuel: stateForm.widthManuel,
        heightManuel: stateForm.heightManuel,
        dist1: stateForm.dist1,
        dist2: stateForm.dist2,
        angle1: stateForm.angle1,
        angle2: stateForm.angle2,
      }
    );

    handleClose();

    // console.log(response.data)
  }

  async function clickDetail(row) {
    console.log(row);
    setDetail(row);
    setValue(0);
    showCircularProgress();
    setSelectedRow(row._id);
    // console.log(row._id)
    var isFirst = isFirstElement(row._id);
    if (isFirst) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }

    var isLast = isLastElement(row._id);
    if (isLast) {
      setPrevDisabled(true);
    } else {
      setPrevDisabled(false);
    }

    //get photos

    //remplir les champs avec les infos de row
    // console.log(row.state)
    if (row.state === true) {
      // console.log("change state -> true")
      state.btnState = true;
    } else {
      // console.log("change state -> false")
      state.btnState = false;
    }

    // console.log("row.siteMeasure =>")
    // console.log(row.siteMeasure)
    // var siteM = row.siteMeasure.split("- ")
    // console.log(siteM[1])
    stateForm.comm = row.commentaire;
    stateForm.siteMes = row.siteMeasure.nomSite;

    stateForm.lengthManuel = row.lengthManuel;
    stateForm.widthManuel = row.widthManuel;
    stateForm.heightManuel = row.heightManuel;
    stateForm.dist1 = row.dist1;
    stateForm.dist2 = row.dist2;
    stateForm.angle1 = row.angle1;
    stateForm.angle2 = row.angle2;
    stateForm.baseMac = row.baseMac;

    //get photo & photo ar
    var photo = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + `/measure/photo?id=${row.idUnique}`,
      { responseType: "arraybuffer" }
    );

    if (photo !== false) {
      var base64 = btoa(
        new Uint8Array(photo.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      stateForm.photo = "data:;base64," + base64;
    }

    var arphoto = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL +
        `/measure/arphoto?id=${row.idUnique}`,
      { responseType: "arraybuffer" }
    );
    if (arphoto !== false) {
      var base64_ar = btoa(
        new Uint8Array(arphoto.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      stateForm.photoAr = "data:;base64," + base64_ar;
    }

    //show popup détail
    handleClickOpen();
    // console.timeEnd("Detail")
    hideCircularProgress();
  }

  async function prevMes() {
    console.log("prev");
    var idMesure = measureDetail._id;
    console.log(idMesure);
    var mes;
    console.log(filterOn);
    if (filterOn) {
      // console.log("filter ON")
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);

      paramsFilter.state = stateFilter;
      // paramsFilter.nbOfPage = 0
      // paramsFilter.nbMeasureDisplay = rowsPerPage + 1
      paramsFilter.unitMsr = unitMesure;

      // if (val !== undefined) {
      //   paramsFilter.unitVolume = val
      // } else {
      //   paramsFilter.unitVolume = unitVol
      // }

      paramsFilter.idMesure = idMesure;

      // console.log("paramsFilter =>")
      // console.log(paramsFilter)

      mes = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL +
          "/measure/getPreviousMesWithFilter",
        {
          params: paramsFilter,
        }
      );
    } else {
      if (infsplit[1] === "user") {
        console.log("NOT admin Piink");
        var sitesID = [];
        identityUser.sites.forEach((elt) => {
          sitesID.push(elt._id);
        });
        console.log("CALL PREV : site => " + sitesID);
        mes = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getPreviousMes",
          {
            params: {
              idMesure: idMesure,
              siteId: sitesID,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        mes = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getPreviousMes",
          {
            params: {
              idMesure: idMesure,
              listPartenaire: identityUser.parts,
            },
          }
        );
      } else {
        // console.log("admin Piink")
        //get previous mesure
        mes = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getPreviousMes",
          {
            params: {
              idMesure: idMesure,
            },
          }
        );
      }
    }

    var row = mes.data[0];
    console.log("row =>");
    console.log(row);

    setDetail(row);
    setValue(0);
    showCircularProgress();

    var isFirst = isFirstElement(row._id);
    if (isFirst) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }

    var isLast = isLastElement(row._id);
    if (isLast) {
      setPrevDisabled(true);
    } else {
      setPrevDisabled(false);
    }

    if (row.state === true) {
      state.btnState = true;
    } else {
      state.btnState = false;
    }

    if (row.commentaire != null) {
      stateForm.comm = row.commentaire;
    } else {
      stateForm.comm = "";
    }

    stateForm.siteMes = row.siteMeasure.nomSite;
    stateForm.lengthManuel = row.lengthManuel;
    stateForm.widthManuel = row.widthManuel;
    stateForm.heightManuel = row.heightManuel;
    stateForm.dist1 = row.dist1;
    stateForm.dist2 = row.dist2;
    stateForm.angle1 = row.angle1;
    stateForm.angle2 = row.angle2;
    stateForm.baseMac = row.baseMac;

    var photo = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + `/measure/photo?id=${row.idUnique}`,
      { responseType: "arraybuffer" }
    );

    if (photo !== false) {
      var base64 = btoa(
        new Uint8Array(photo.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      stateForm.photo = "data:;base64," + base64;
    }

    var arphoto = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL +
        `/measure/arphoto?id=${row.idUnique}`,
      { responseType: "arraybuffer" }
    );

    if (arphoto !== false) {
      var base64_ar = btoa(
        new Uint8Array(arphoto.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      stateForm.photoAr = "data:;base64," + base64_ar;
    }

    hideCircularProgress();
  }

  async function nextMes() {
    var idMesure = measureDetail._id;
    console.log(idMesure);

    if (filterOn) {
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);

      paramsFilter.state = stateFilter;
      paramsFilter.unitMsr = unitMesure;

      paramsFilter.idMesure = idMesure;

      console.log("paramsFilter =>");
      console.log(paramsFilter);

      var mes = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/getNextMesWithFilter",
        {
          params: paramsFilter,
        }
      );
    } else {
      //## pass site into request
      if (infsplit[1] === "user") {
        var sitesID = [];
        identityUser.sites.forEach((elt) => {
          sitesID.push(elt._id);
        });

        var mes = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getNextMes",
          {
            params: {
              idMesure: idMesure,
              siteId: sitesID,
            },
          }
        );
      } else if (infsplit[1] === "partenaire") {
        var mes = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getNextMes",
          {
            params: {
              idMesure: idMesure,
              listPartenaire: identityUser.parts,
            },
          }
        );
      } else {
        //get next mesure
        var mes = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getNextMes",
          {
            params: {
              idMesure: idMesure,
            },
          }
        );
      }
    }

    var row = mes.data[0];
    setDetail(row);
    setValue(0);
    showCircularProgress();

    var isFirst = isFirstElement(row._id);
    if (isFirst) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }

    var isLast = isLastElement(row._id);
    if (isLast) {
      setPrevDisabled(true);
    } else {
      setPrevDisabled(false);
    }

    if (row.state === true) {
      state.btnState = true;
    } else {
      state.btnState = false;
    }

    if (row.commentaire != null) {
      stateForm.comm = row.commentaire;
    } else {
      stateForm.comm = "";
    }

    if (row.siteMeasure != null) {
      if (row.siteMeasure.nomSite != null) {
        stateForm.siteMes = row.siteMeasure.nomSite;
      } else {
        stateForm.siteMes = "";
      }
    } else {
      stateForm.siteMes = "";
    }

    stateForm.lengthManuel = row.lengthManuel;
    stateForm.widthManuel = row.widthManuel;
    stateForm.heightManuel = row.heightManuel;
    stateForm.dist1 = row.dist1;
    stateForm.dist2 = row.dist2;
    stateForm.angle1 = row.angle1;
    stateForm.angle2 = row.angle2;
    stateForm.baseMac = row.baseMac;

    var photo = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + `/measure/photo?id=${row.idUnique}`,
      { responseType: "arraybuffer" }
    );

    if (photo !== false) {
      var base64 = btoa(
        new Uint8Array(photo.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      stateForm.photo = "data:;base64," + base64;
    }

    var arphoto = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL +
        `/measure/arphoto?id=${row.idUnique}`,
      { responseType: "arraybuffer" }
    );

    if (arphoto !== false) {
      var base64_ar = btoa(
        new Uint8Array(arphoto.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      stateForm.photoAr = "data:;base64," + base64_ar;
    }

    hideCircularProgress();
  }

  function showCircularProgress() {
    setOpenLoad(true);
  }

  function hideCircularProgress() {
    setOpenLoad(false);
  }

  function isFirstElement(id) {
    if (page == 0 && id == measures[0]._id) {
      return true;
    }
    return false;
  }

  function isLastElement(id) {
    if (measures.length < rowsPerPage) {
      if (measures[measures.length - 1]._id == id) {
        return true;
      }
    }
    return false;
  }

  async function callSimple() {
    setIsDataCharging(true);
    var response3 = [];
    var sitesID = [];
    console.log("call simple");
    // console.log(stateFormFilter.id)
    // console.log("identityUser")
    // console.log(identityUser)
    // console.log(unitMesure)
    var response2 = [];
    if (firstCall === false) {
      if (infsplit[1] === "adminPiink") {
        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: rowsPerPage + 1,
              unitMsr: unitMesure,
            },
          }
        );

        response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/count"
        );
        setPagination({ entriesLength: response3.data });
      } else if (infsplit[1] === "partenaire") {
        //then get Measure from partName
        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL +
            "/measure/getMeasuresForPartenaire",
          {
            params: {
              listPartenaire: identityUser.parts,
              nbOfPage: 0,
              nbMeasureDisplay: rowsPerPage + 1,
              unitMsr: unitMesure,
            },
          }
        );

        response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/count",
          {
            params: {
              listPartenaire: identityUser.parts,
            },
          }
        );
        setPagination({ entriesLength: response3.data });
      } else {
        identityUser.sites.forEach((elt) => {
          sitesID.push(elt._id);
        });

        response2 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getMeasuresForUser",
          {
            params: {
              nbOfPage: 0,
              nbMeasureDisplay: rowsPerPage + 1,
              siteUser: sitesID,
              unitMsr: unitMesure,
            },
          }
        );
        response3 = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/count",
          {
            params: {
              siteUser: sitesID,
            },
          }
        );
        setPagination({ entriesLength: response3.data });
      }

      setMeasures(response2.data);
      console.log("call from callsimple");
      calculVolumeTotal(
        false,
        null,
        sitesID,
        unitVolTT,
        identityUser.role,
        identityUser.parts
      );
    }

    setIsDataCharging(false);
  }

  async function callRefresh(unitMes) {
    setStateFormFilter({
      id: "",
      id1: "",
      id2: "",
      long: "",
      larg: "",
      haut: "",
      pds: "",
      date: "",
      date2: "",
      term: "",
      type: "",
      exp: "",
      des: "",
      vol: "",
      siteMes: "",
    });

    setStateFilter("");
    setTabRowChecked([]);
    setPage(0);
    setFilterOnOff(false);

    //#ok#
    var response3 = [];

    // console.log(stateFormFilter.id)
    var response2 = [];
    if (infsplit[1] === "adminPiink") {
      response2 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure",
        {
          params: {
            nbOfPage: 0,
            nbMeasureDisplay: rowsPerPage + 1,
            unitMsr: unitMes,
          },
        }
      );

      response3 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/count"
      );
      setPagination({ entriesLength: response3.data });
    } else if (infsplit[1] === "partenaire") {
      //then get Measure from partName
      response2 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL +
          "/measure/getMeasuresForPartenaire",
        {
          params: {
            listPartenaire: identityUser.parts,
            nbOfPage: 0,
            nbMeasureDisplay: rowsPerPage + 1,
            unitMsr: unitMesure,
          },
        }
      );

      response3 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/count",
        {
          params: {
            listPartenaire: identityUser.parts,
          },
        }
      );
      setPagination({ entriesLength: response3.data });
    } else {
      var sitesID = [];
      identityUser.sites.forEach((elt) => {
        sitesID.push(elt._id);
      });

      response2 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/getMeasuresForUser",
        {
          params: {
            nbOfPage: 0,
            nbMeasureDisplay: rowsPerPage + 1,
            siteUser: sitesID,
            unitMsr: unitMes,
          },
        }
      );

      response3 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/count",
        {
          params: {
            siteUser: sitesID,
          },
        }
      );
      setPagination({ entriesLength: response3.data });
    }

    setMeasures(response2.data);
    var volTT = 0;
    var resp;
    var uVolume = "";
    if (unitMesure === "cm") {
      uVolume = "M";
    } else {
      uVolume = "FT";
    }
    console.log("infsplit[1] get tt");
    console.log(infsplit[1]);

    if (infsplit[1] === "adminPiink") {
      resp = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
        {
          params: {
            unitVolume: uVolume,
          },
        }
      );
    } else if (infsplit[1] === "partenaire") {
      resp = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
        {
          params: {
            listPartenaire: identityUser.parts,
            unitVolume: uVolume,
          },
        }
      );
    } else {
      // console.log(identityUser.sites)
      var sitesID = [];
      identityUser.sites.forEach((elt) => {
        sitesID.push(elt._id);
      });

      resp = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
        {
          params: {
            siteUser: sitesID,
            unitVolume: uVolume,
          },
        }
      );
    }
    volTT = resp.data;
    setVolumeTotal(volTT);
  }

  async function ResetFilters() {
    // console.log("reset")
    // console.log(stateFormFilter.id)
    setStateFormFilter({
      id: "",
      id1: "",
      id2: "",
      long: "",
      larg: "",
      haut: "",
      pds: "",
      date: "",
      date2: "",
      term: "",
      type: "",
      exp: "",
      des: "",
      vol: "",
      siteMes: "",
    });

    setStateFilter("");
    setTabRowChecked([]);
    setPage(0);
    setFilterOnOff(false);
  }

  async function callFilter(val) {
    var sitesID = [];
    setIsDataCharging(true);
    console.log("call filter");
    // console.log(stateFormFilter)
    var paramsFilter = {};
    paramsFilter = filterConditions(paramsFilter);
    setPage(0);
    paramsFilter.state = stateFilter;
    paramsFilter.nbOfPage = 0;
    paramsFilter.nbMeasureDisplay = rowsPerPage + 1;
    paramsFilter.unitMsr = unitMesure;
    if (val !== undefined) {
      paramsFilter.unitVolume = val;
    } else {
      paramsFilter.unitVolume = unitVol;
    }

    if (paramsFilter.date !== undefined && paramsFilter.date !== "") {
      if (identityUser.langue === "FR") {
        var dateD = moment(paramsFilter.date).format("DD/MM/YYYY");
      } else {
        var dateD = moment(paramsFilter.date).format("MM/DD/YYYY");
      }
      setStartDate(dateD);
    }

    // console.log(paramsFilter)

    if (infsplit[1] === "user") {
      identityUser.sites.forEach((elt) => {
        sitesID.push(elt._id);
      });
      console.log("sitesID");
      console.log(sitesID);
      paramsFilter.siteUser = sitesID;
    } else if (infsplit[1] === "partenaire") {
      paramsFilter.listPartenaire = identityUser.parts;
    }
    console.log("hello ?");
    //################

    await axios.defaultAxios
      .get(axios.defaultAxios.defaults.baseURL + "/measure/measureFilter", {
        params: paramsFilter,
        cancelToken: source.token,
      })
      .then((response) => {
        // console.log("rowsPerPage : " + (rowsPerPage + 1))
        setTabRowChecked([]);
        paramsFilter.unitVolume = unitVolTT;
        console.log("call from callfilter");
        calculVolumeTotal(
          true,
          paramsFilter,
          sitesID,
          unitVolTT,
          identityUser.role,
          identityUser.parts
        );
        console.log(response.data);
        setFilterOnOff(true);
        setMeasures(response.data);
      });

    var response3 = await axios.defaultAxios.get(
      axios.defaultAxios.defaults.baseURL + "/measure/countFilter",
      {
        params: paramsFilter,
        cancelToken: source.token,
      }
    );
    setPagination({ entriesLength: response3.data });

    setIsDataCharging(false);
    //################
  }

  async function buildParamFilter() {
    var paramsFilter = {};
    paramsFilter = filterConditions(paramsFilter);
    setPage(0);
    paramsFilter.state = stateFilter;
    paramsFilter.nbOfPage = 0;
    paramsFilter.nbMeasureDisplay = rowsPerPage + 1;
    paramsFilter.unitMsr = unitMesure;
    // if (val !== undefined) {
    //   paramsFilter.unitVolume = val
    // } else {
    paramsFilter.unitVolume = unitVol;
    // }

    if (paramsFilter.date !== undefined && paramsFilter.date !== "") {
      if (identityUser.langue === "FR") {
        var dateD = moment(paramsFilter.date).format("DD/MM/YYYY");
      } else {
        var dateD = moment(paramsFilter.date).format("MM/DD/YYYY");
      }
      setStartDate(dateD);
    }

    // console.log(paramsFilter)

    if (infsplit[1] === "user") {
      var sitesID = [];
      identityUser.sites.forEach((elt) => {
        sitesID.push(elt._id);
      });
      // console.log("sitesID")
      // console.log(sitesID)
      paramsFilter.siteUser = sitesID;
    } else if (infsplit[1] === "partenaire") {
      paramsFilter.listPartenaire = identityUser.parts;
    }

    return paramsFilter;
  }

  async function calculVolumeTotal(
    isFilterCall,
    Tab,
    siteU,
    unit,
    role,
    listP
  ) {
    console.log("calculVolumeTotal");
    console.log("identityUser => ");
    console.log(identityUser);
    var roleOfU = identityUser.role;
    if (identityUser.mail == "") {
      roleOfU = role;
    }
    console.log(roleOfU);
    var volTT = 0;
    var resp;
    // console.log(infsplit[1])
    // console.log("isFilterCall : " + isFilterCall)
    if (isFilterCall) {
      //call volume pour le param Tab
      resp = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/getVolTTFilter",
        {
          params: Tab,
          cancelToken: source.token,
        }
      );
      console.log(resp);
    } else {
      //call volume sur toute la table
      //#ok#
      if (unit !== null) {
        // console.log(unitMesure)
        if (roleOfU === "adminPiink") {
          resp = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
            {
              params: {
                unitMsr: unitMesure,
                unitVolume: unit,
                cancelToken: source.token,
              },
            }
          );
        } else if (roleOfU === "partenaire") {
          console.log("call getVolTT from part");
          resp = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
            {
              params: {
                listPartenaire: listP,
                unitMsr: unitMesure,
                unitVolume: unit,
                cancelToken: source.token,
              },
            }
          );
        } else {
          console.log("call getVolTT from user");
          // console.log(identityUser.sites)
          resp = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
            {
              params: {
                siteUser: siteU,
                unitMsr: unitMesure,
                unitVolume: unit,
                cancelToken: source.token,
              },
            }
          );
        }
      } else {
        // console.log(unitMesure)
        if (infsplit[1] === "adminPiink") {
          resp = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
            {
              params: {
                unitMsr: unitMesure,
                unitVolume: unit,
                cancelToken: source.token,
              },
            }
          );
        } else if (infsplit[1] === "partenaire") {
          resp = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
            {
              params: {
                listPartenaire: listP,
                unitMsr: unitMesure,
                unitVolume: unit,
                cancelToken: source.token,
              },
            }
          );
        } else {
          // console.log(identityUser.sites)
          resp = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
            {
              params: {
                siteUser: siteU,
                unitMsr: unitMesure,
                unitVolume: unit,
                cancelToken: source.token,
              },
            }
          );
        }
      }
    }
    // // console.log(Tab)
    // for(var i=0; i<Tab.length; i++){
    //   var volMesure = ((Tab[i].length).split(' ')[0] * (Tab[i].width).split(' ')[0] * (Tab[i].height).split(' ')[0] / 1000000).toFixed(3)
    //   tabVolume.push(volMesure)
    //   volTT = parseFloat(volTT) + parseFloat(volMesure)
    // }

    // console.log(tabVolume)
    // console.log(volTT)
    volTT = resp.data;
    setVolumeTotal(volTT);
  }

  function pressEnter(ev) {
    if (ev.key === "Enter") {
      console.log("ENTER KEY pressed !");
      callFilter();
      ev.preventDefault();
    }
  }

  function filterConditions(paramsFilter) {
    if (stateFormFilter.id !== "" && stateFormFilter.id !== undefined) {
      paramsFilter.id = stateFormFilter.id; //{ $regex: /789$/ }
    }
    if (stateFormFilter.id1 !== "" && stateFormFilter.id1 !== undefined) {
      paramsFilter.id1 = stateFormFilter.id1; //{ $regex: /789$/ }
    }
    if (stateFormFilter.id2 !== "" && stateFormFilter.id2 !== undefined) {
      paramsFilter.id2 = stateFormFilter.id2; //{ $regex: /789$/ }
    }
    if (stateFormFilter.long !== "" && stateFormFilter.long !== undefined) {
      paramsFilter.length = stateFormFilter.long;
    }
    if (stateFormFilter.larg !== "" && stateFormFilter.larg !== undefined) {
      paramsFilter.width = stateFormFilter.larg;
    }
    if (stateFormFilter.haut !== "" && stateFormFilter.haut !== undefined) {
      paramsFilter.height = stateFormFilter.haut;
    }
    if (stateFormFilter.pds !== "" && stateFormFilter.pds !== undefined) {
      paramsFilter.weight = stateFormFilter.pds;
    }
    if (stateFormFilter.date !== "" && stateFormFilter.date !== undefined) {
      paramsFilter.date = stateFormFilter.date;
    }
    if (stateFormFilter.date2 !== "" && stateFormFilter.date2 !== undefined) {
      paramsFilter.date2 = stateFormFilter.date2;
    }
    if (stateFormFilter.term !== "" && stateFormFilter.term !== undefined) {
      paramsFilter.device = stateFormFilter.term;
    }
    if (stateFormFilter.type !== "" && stateFormFilter.type !== undefined) {
      paramsFilter.type = stateFormFilter.type;
    }
    if (stateFormFilter.exp !== "" && stateFormFilter.exp !== undefined) {
      paramsFilter.id = stateFormFilter.exp;
    }
    if (stateFormFilter.des !== "" && stateFormFilter.des !== undefined) {
      paramsFilter.id = stateFormFilter.des;
    }
    if (
      stateFormFilter.siteMes !== "" &&
      stateFormFilter.siteMes !== undefined
    ) {
      paramsFilter.siteMes = stateFormFilter.siteMes;
    }

    if (stateFormFilter.vol !== "" && stateFormFilter.vol !== undefined) {
      paramsFilter.vol = stateFormFilter.vol;
      paramsFilter.supOfInf = supOrInfVar;
    }

    return paramsFilter;
  }

  // function changeCheckbox(e, row){

  //   // console.log(e.target.checked)

  //   if (tabRowChecked.includes(row._id)){
  //     if (!e.target.checked){
  //       var ind = tabRowChecked.indexOf(row._id)
  //       tabRowChecked.splice(ind, 1);

  //       //removing volume
  //       var volMesure = ((row.length).split(' ')[0] * (row.width).split(' ')[0] * (row.height).split(' ')[0] / 1000000)
  //       var volcalul = parseFloat(volTTChecked) - parseFloat(volMesure)
  //       setVolTTChecked(volcalul)
  //     }

  //   }else {
  //     if (e.target.checked){
  //       tabRowChecked.push(row._id)

  //       //adding volume
  //       var volMesure = ((row.length).split(' ')[0] * (row.width).split(' ')[0] * (row.height).split(' ')[0] / 1000000)
  //       var volcalul = parseFloat(volTTChecked) + parseFloat(volMesure)
  //       setVolTTChecked(volcalul)
  //     }

  //   }
  // }

  // function showVolChecked(){
  //   if(tabRowChecked.length > 0){
  //     setVolumeTotal(volTTChecked.toFixed(3))
  //   }else {
  //     //bug si pagination ou filter -> faire btn reset calcul vol qui reset filtrage + volume et nb page
  //     resetVolChecked()
  //   }
  // }

  async function resetVolChecked() {
    console.log("reset vol called");
    // setChecked(false)
    setTabRowChecked([]);
    setVolTTChecked(0);
    //#ok#
    var resp = [];
    var uVolume = "";
    if (unitMesure === "cm") {
      uVolume = "M";
    } else {
      uVolume = "FT";
    }
    if (infsplit[1] === "adminPiink") {
      resp = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
        {
          params: {
            unitVolume: uVolume,
          },
        }
      );
    } else if (infsplit[1] === "partenaire") {
      resp = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
        {
          params: {
            unitVolume: uVolume,
            listPartenaire: identityUser.parts,
          },
        }
      );
    } else {
      var sitesID = [];
      identityUser.sites.forEach((elt) => {
        sitesID.push(elt._id);
      });

      resp = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
        {
          params: {
            siteUser: sitesID,
            unitVolume: uVolume,
          },
        }
      );
    }
    var volTT = resp.data;
    console.log(volTT);
    setVolumeTotal(volTT);
    setSelected([]);

    setPage(0);
    setFilterOnOff(false);
    setStateFormFilter({
      id: "",
      id1: "",
      id2: "",
      long: "",
      larg: "",
      haut: "",
      pds: "",
      date: "",
      date2: "",
      term: "",
      type: "",
      exp: "",
      des: "",
      vol: "",
      siteMes: "",
    });

    //#ok#  correct params
    var response3 = [];
    // console.log(stateFormFilter.id)

    var response2 = [];
    if (infsplit[1] === "adminPiink") {
      response2 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure",
        {
          params: {
            nbOfPage: 0,
            nbMeasureDisplay: rowsPerPage + 1,
            unitMsr: unitMesure,
          },
        }
      );
      response3 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/count"
      );
      setPagination({ entriesLength: response3.data });
    } else if (infsplit[1] === "partenaire") {
      //then get Measure from partName
      response2 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL +
          "/measure/getMeasuresForPartenaire",
        {
          params: {
            listPartenaire: identityUser.parts,
            nbOfPage: 0,
            nbMeasureDisplay: rowsPerPage + 1,
            unitMsr: unitMesure,
          },
        }
      );

      response3 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/count",
        {
          params: {
            listPartenaire: identityUser.parts,
          },
        }
      );
      setPagination({ entriesLength: response3.data });
    } else {
      var sitesID = [];
      identityUser.sites.forEach((elt) => {
        sitesID.push(elt._id);
      });

      response2 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/getMeasuresForUser",
        {
          params: {
            nbOfPage: 0,
            nbMeasureDisplay: rowsPerPage + 1,
            siteUser: sitesID,
            unitMsr: unitMesure,
          },
        }
      );
      response3 = await axios.defaultAxios.get(
        axios.defaultAxios.defaults.baseURL + "/measure/count",
        {
          params: {
            siteUser: sitesID,
          },
        }
      );
      setPagination({ entriesLength: response3.data });
    }

    setMeasures(response2.data);
  }

  async function handleSelectAllClick(event) {
    console.log(selected.length);
    console.log(event.target.checked);
    if (event.target.checked && selected.length < measures.length) {
      var newSelected = measures.map((n) => n._id);
      measures.map((n) => selectRow(event, n));
      setSelected(newSelected);
      return;
    }
    setTabRowChecked([]);
    setSelected([]);

    var resp = [];
    var paramsFilter = {};
    paramsFilter = filterConditions(paramsFilter);

    paramsFilter.state = stateFilter;
    paramsFilter.nbOfPage = page;
    paramsFilter.nbMeasureDisplay = rowsPerPage;
    paramsFilter.unitMsr = unitMesure;
    paramsFilter.unitVolume = unitVolTT;
    if (infsplit[1] === "adminPiink") {
      if (filterOn) {
        calculVolumeTotal(
          true,
          paramsFilter,
          unitVolTT,
          identityUser.role,
          identityUser.parts
        );
      } else {
        resp = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
          {
            params: {
              unitVolume: unitVolTT,
            },
          }
        );
      }
    } else if (infsplit[1] === "partenaire") {
      if (filterOn) {
        calculVolumeTotal(
          true,
          paramsFilter,
          unitVolTT,
          identityUser.role,
          identityUser.parts
        );
      } else {
        resp = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
          {
            params: {
              listPartenaire: identityUser.parts,
              unitVolume: unitVolTT,
            },
          }
        );
      }
    } else {
      // console.log(identityUser.sites)
      // console.log("uVolume")
      // console.log(uVolume)
      if (filterOn) {
        calculVolumeTotal(
          true,
          paramsFilter,
          unitVolTT,
          identityUser.role,
          identityUser.parts
        );
      } else {
        var sitesID = [];
        identityUser.sites.forEach((elt) => {
          sitesID.push(elt._id);
        });

        resp = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
          {
            params: {
              siteUser: sitesID,
              unitVolume: unitVolTT,
            },
          }
        );
      }
    }
    var volTT = resp.data;
    setVolumeTotal(volTT);
  }

  async function selectRow(event, rowInfo) {
    // console.log(event.target);
    const selectedIndex = selected.indexOf(rowInfo._id);
    // console.log(rowInfo)
    // console.log("selectRow")
    // console.log(selectedIndex)
    // if (!lockSelectRow){
    //change selected state
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowInfo._id);
      tabRowChecked.push(rowInfo);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      tabRowChecked.splice(selectedIndex, 1);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      tabRowChecked.splice(selectedIndex, 1);
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      tabRowChecked.splice(selectedIndex, 1);
    }

    // console.log(newSelected)
    // console.log(tabRowChecked);
    // console.log("in select row");
    // console.log(newSelected);
    setSelected(newSelected);

    if (tabRowChecked.length > 0) {
      setStateSuppMultiple(false);
      //add or sub the volumeTT
      // var volTTSelected = 0
      //Modif ICI
      var volcalul = 0;
      if (unitVolTT === "M") {
        if (unitMesure === "cm") {
          tabRowChecked.forEach((elt) => {
            var mesure =
              (elt.length.split(" ")[0] *
                elt.width.split(" ")[0] *
                elt.height.split(" ")[0]) /
              1000000;
            // console.log(mesure)
            volcalul = parseFloat(volcalul) + parseFloat(mesure);
          });
        } else if (unitMesure === "in") {
          //change unit before adding
          tabRowChecked.forEach((elt) => {
            var mesure =
              (elt.length.split(" ")[0] *
                2.54 *
                (elt.width.split(" ")[0] * 2.54) *
                (elt.height.split(" ")[0] * 2.54)) /
              1000000;
            // console.log(mesure)
            volcalul = parseFloat(volcalul) + parseFloat(mesure);
          });
        }
      } else if (unitVolTT === "FT") {
        if (unitMesure === "cm") {
          //change unit before adding
          tabRowChecked.forEach((elt) => {
            var mesure =
              ((elt.length.split(" ")[0] / 2.54) *
                (elt.width.split(" ")[0] / 2.54) *
                (elt.height.split(" ")[0] / 2.54)) /
              1728;
            // console.log(mesure)
            volcalul = parseFloat(volcalul) + parseFloat(mesure);
          });
        } else if (unitMesure === "in") {
          tabRowChecked.forEach((elt) => {
            var mesure =
              (elt.length.split(" ")[0] *
                elt.width.split(" ")[0] *
                elt.height.split(" ")[0]) /
              1728;
            // console.log(mesure)
            volcalul = parseFloat(volcalul) + parseFloat(mesure);
          });
        }
      } else if (unitVolTT === "CM") {
        if (unitMesure === "cm") {
          tabRowChecked.forEach((elt) => {
            var mesure =
              elt.length.split(" ")[0] *
              elt.width.split(" ")[0] *
              elt.height.split(" ")[0];
            // console.log(mesure)
            volcalul = parseFloat(volcalul) + parseFloat(mesure);
          });
        } else if (unitMesure === "in") {
          //change unit before adding
          tabRowChecked.forEach((elt) => {
            var mesure =
              elt.length.split(" ")[0] *
              2.54 *
              (elt.width.split(" ")[0] * 2.54) *
              (elt.height.split(" ")[0] * 2.54);
            // console.log(mesure)
            volcalul = parseFloat(volcalul) + parseFloat(mesure);
          });
        }
      } else if (unitVolTT === "L") {
        if (unitMesure === "cm") {
          tabRowChecked.forEach((elt) => {
            var mesure =
              (elt.length.split(" ")[0] *
                elt.width.split(" ")[0] *
                elt.height.split(" ")[0]) /
              1000;
            // console.log(mesure)
            volcalul = parseFloat(volcalul) + parseFloat(mesure);
          });
        } else if (unitMesure === "in") {
          //change unit before adding
          tabRowChecked.forEach((elt) => {
            var mesure =
              (elt.length.split(" ")[0] *
                2.54 *
                (elt.width.split(" ")[0] * 2.54) *
                (elt.height.split(" ")[0] * 2.54)) /
              1000;
            // console.log(mesure)
            volcalul = parseFloat(volcalul) + parseFloat(mesure);
          });
        }
      }

      // setVolumeTotal(volTTChecked.toFixed(3))
      // console.log(volcalul)
      setVolTTChecked(volcalul);
      setVolumeTotal(volcalul.toFixed(3));
    } else {
      setStateSuppMultiple(true);
      //get volume total
      // console.log(unitMesure)
      // var uVolume = ""
      // if (unitMesure === "cm") {
      //   uVolume = "M"
      // } else {
      //   uVolume = "FT"
      // }
      var resp = [];
      var paramsFilter = {};
      paramsFilter = filterConditions(paramsFilter);

      paramsFilter.state = stateFilter;
      paramsFilter.nbOfPage = page;
      paramsFilter.nbMeasureDisplay = rowsPerPage;
      paramsFilter.unitMsr = unitMesure;
      paramsFilter.unitVolume = unitVolTT;
      if (infsplit[1] === "adminPiink") {
        if (filterOn) {
          calculVolumeTotal(
            true,
            paramsFilter,
            unitVolTT,
            identityUser.role,
            identityUser.parts
          );
        } else {
          resp = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
            {
              params: {
                unitVolume: unitVolTT,
              },
            }
          );
        }
      } else if (infsplit[1] === "partenaire") {
        if (filterOn) {
          calculVolumeTotal(
            true,
            paramsFilter,
            unitVolTT,
            identityUser.role,
            identityUser.parts
          );
        } else {
          resp = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
            {
              params: {
                listPartenaire: identityUser.parts,
                unitVolume: unitVolTT,
              },
            }
          );
        }
      } else {
        // console.log(identityUser.sites)
        // console.log("uVolume")
        // console.log(uVolume)
        if (filterOn) {
          calculVolumeTotal(
            true,
            paramsFilter,
            unitVolTT,
            identityUser.role,
            identityUser.parts
          );
        } else {
          var sitesID = [];
          identityUser.sites.forEach((elt) => {
            sitesID.push(elt._id);
          });

          resp = await axios.defaultAxios.get(
            axios.defaultAxios.defaults.baseURL + "/measure/getVolTT",
            {
              params: {
                siteUser: sitesID,
                unitVolume: unitVolTT,
              },
            }
          );
        }
      }
      var volTT = resp.data;
      setVolumeTotal(volTT);
    }

    // }
  }

  function toggleRightBarPanel(valToChange) {
    setRightBarState(!valToChange);
  }

  // function setDateString(){
  //   var dateToday = new Date()
  //   var day = dateToday.getDate()
  //   var month = dateToday.getUTCMonth()
  //   var year = dateToday.getFullYear()

  //   var dateOfTodayVar = ""
  //   if (i18n.language === "fr"){
  //     month = monthFr[month]
  //   } else if (i18n.language === "en"){
  //     month = monthEn[month]
  //   }
  //   dateOfTodayVar = day + " " + month + " " + year
  //   // console.log(dateOfTodayVar)
  //   return (dateOfTodayVar)

  // }

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const isSelectedDetail = (id) => selectedRow.indexOf(id) !== -1;

  async function callExportExcel() {
    // console.log("callExportExcel")
    if (dateExport.dateD !== "") {
      var responseExport = [];

      if (infsplit[1] === "adminPiink") {
        console.log(dateExport.dateD);
        console.log(dateExport.dateF);

        responseExport = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getMesExport",
          {
            params: {
              dateDebut: dateExport.dateD,
              dateFin: dateExport.dateF,
            },
          }
        );

        // console.log(responseExport.data)
      } else if (infsplit[1] === "partenaire") {
        responseExport = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getMesExport",
          {
            params: {
              listPartenaire: identityUser.parts,
              dateDebut: dateExport.dateD,
              dateFin: dateExport.dateF,
            },
          }
        );
      } else {
        var sitesID = [];
        identityUser.sites.forEach((elt) => {
          sitesID.push(elt._id);
        });

        responseExport = await axios.defaultAxios.get(
          axios.defaultAxios.defaults.baseURL + "/measure/getMesExport",
          {
            params: {
              listSite: sitesID,
              dateDebut: dateExport.dateD,
              dateFin: dateExport.dateF,
            },
          }
        );

        // console.log(responseExport.data)
      }

      // console.log(buttonExportRef.current)

      console.log(responseExport.data);
      // for (var i = 0; i < responseExport.data.length; i++){
      //   responseExport.data[i].siteMeasure = responseExport.data[i].siteMeasure.nomSite
      // }

      setExportExcel(responseExport.data);
      buttonExportRef.current.click();
    } else {
      setOpenMsgErrorDateExp(true);
    }
  }

  function openExportPopup() {
    setOpenExport(true);
  }
  function openExportPopup2() {
    setOpenExport2(true);
  }
  function opentExportDev() {
    setOpenExportDev(true);
  }

  // console.log("window.location.search ->")
  // console.log(window.location.search)

  function callCalculVol(row) {
    var volume = 0;
    switch (unitVol) {
      case "M":
        if (unitMesure === "cm") {
          volume = (
            (row.length.split(" ")[0] *
              row.width.split(" ")[0] *
              row.height.split(" ")[0]) /
            1000000
          ).toFixed(3);
        } else {
          volume = (
            (row.length.split(" ")[0] *
              2.54 *
              (row.width.split(" ")[0] * 2.54) *
              (row.height.split(" ")[0] * 2.54)) /
            1000000
          ).toFixed(3);
        }

        var volDisplay = volume + " m³";
        return volDisplay;

      case "CM":
        if (unitMesure === "cm") {
          volume = (
            row.length.split(" ")[0] *
            row.width.split(" ")[0] *
            row.height.split(" ")[0]
          ).toFixed(3);
        } else {
          volume = (
            row.length.split(" ")[0] *
            2.54 *
            (row.width.split(" ")[0] * 2.54) *
            (row.height.split(" ")[0] * 2.54)
          ).toFixed(3);
        }

        var volDisplay = volume + " cm³";
        return volDisplay;

      case "FT":
        if (unitMesure === "cm") {
          volume = (
            ((row.length.split(" ")[0] / 2.54) *
              (row.width.split(" ")[0] / 2.54) *
              (row.height.split(" ")[0] / 2.54)) /
            1728
          ).toFixed(3);
        } else {
          volume = (
            (row.length.split(" ")[0] *
              row.width.split(" ")[0] *
              row.height.split(" ")[0]) /
            1728
          ).toFixed(3);
        }

        var volDisplay = volume + " ft³";
        return volDisplay;

      case "L":
        if (unitMesure === "cm") {
          volume = (
            (row.length.split(" ")[0] *
              row.width.split(" ")[0] *
              row.height.split(" ")[0]) /
            1000
          ).toFixed(3);
        } else {
          volume = (
            (row.length.split(" ")[0] *
              2.54 *
              (row.width.split(" ")[0] * 2.54) *
              (row.height.split(" ")[0] * 2.54)) /
            1000
          ).toFixed(3);
        }

        var volDisplay = volume + " L";
        return volDisplay;
    }
  }

  if (window.location.search.includes("authorizationCode")) {
    // console.log("REDIRECT en cour")
    return <Redirect to="/mesures" />;
  }

  async function download(event, idMes) {
    // console.log("DOWNLOAD...")

    axios
      .defaultAxios({
        url:
          axios.defaultAxios.defaults.baseURL +
          "/measure/getAllPointCloudForMesure?id=" +
          idMes,
        method: "GET",
        responseType: "blob", // important
      })
      .then((response) => {
        console.log(response.data.size);
        if (response.data.size > 0) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "cp_" + idMes + ".zip");
          document.body.appendChild(link);
          link.click();
        } else {
          //msg erreur
          console.log("pas de fichier a télécharger");
          setOpenMsgErrorMsgNoCP(true);
        }
      });
  }

  // async function downloadMultiple(event){
  //   console.log("downloadMultiple")
  //   var bodyOfReq = ""
  //   var dateNow = new Date()
  //   if (siteToExport !== ""){
  //     //get id from siteToExport
  //     var site = await axios.customAxios(axios.customAxios.defaults.baseURL + '/site/getIdSiteFromName', {
  //       params: {
  //         siteToSearch: siteToExport
  //       }
  //     })
  //     // console.log(site.data)

  //     bodyOfReq += "siteToExport="+site.data+"&"
  //   }

  //   if (dateExport.dateD !== "" && dateExport.dateF !== ""){
  //     bodyOfReq += "startDateToExport=" + dateExport.dateD + "&"
  //     bodyOfReq += "endDateToExport=" + dateExport.dateF + "&"
  //   }

  //   if (exportMultiMode.mode !== ""){
  //     bodyOfReq += "mode=" + exportMultiMode.mode
  //   }

  //   axios.defaultAxios({
  //     url: axios.defaultAxios.defaults.baseURL + '/measure/getcpForMultiple?'+bodyOfReq,
  //     method: 'GET',
  //     responseType: 'blob', // important
  //   }).then((response) => {
  //     console.log(response.data.size)
  //     if (response.data.size > 0) {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', 'cpMultiple_' + dateNow + '.zip');
  //       document.body.appendChild(link);
  //       link.click();
  //     } else {
  //       //msg erreur
  //       console.log("pas de fichier a télécharger")
  //       setOpenMsgErrorMsgNoCP(true)
  //     }

  //   });
  // }

  async function downloadDev(event) {
    console.log("call downloadDev");
    axios
      .defaultAxios({
        url:
          axios.defaultAxios.defaults.baseURL +
          "/measure/getAllPhotosDev?nbPhoto=" +
          exportDev.nbPhotos,
        method: "GET",
        responseType: "blob", // important
      })
      .then((response) => {
        console.log("response from downloadDev => ");
        console.log(response.data);
        console.log(response.data.size);
        if (response.data.size > 0) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "photosMultiple.zip");
          document.body.appendChild(link);
          link.click();
        } else {
          //msg erreur
          console.log("pas de fichier a télécharger");
          setOpenMsgErrorMsgNoCP(true);
        }
      });

    //Export cp CMP
    // axios.defaultAxios({
    //   url: axios.defaultAxios.defaults.baseURL + '/measure/getAllcpFromCMP?nbPhoto=' + exportDev.nbPhotos,
    //   method: 'GET',
    //   responseType: 'blob', // important
    // }).then((response) => {
    //   console.log("response from downloadDev => ")
    //   console.log(response.data)
    //   console.log(response.data.size)
    //   if (response.data.size > 0) {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', 'photosMultiple.zip');
    //     document.body.appendChild(link);
    //     link.click();
    //   } else {
    //     //msg erreur
    //     console.log("pas de fichier a télécharger")
    //     setOpenMsgErrorMsgNoCP(true)
    //   }

    // });
  }

  async function downloadDev2(event) {
    console.log("call downloadDev2");
    // axios.defaultAxios({
    //   url: axios.defaultAxios.defaults.baseURL + '/measure/getAllPhotosDev?nbPhoto=' + exportDev.nbPhotos,
    //   method: 'GET',
    //   responseType: 'blob', // important
    // }).then((response) => {
    //   console.log("response from downloadDev => ")
    //   console.log(response.data)
    //   console.log(response.data.size)
    //   if (response.data.size > 0) {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', 'photosMultiple.zip');
    //     document.body.appendChild(link);
    //     link.click();
    //   } else {
    //     //msg erreur
    //     console.log("pas de fichier a télécharger")
    //     setOpenMsgErrorMsgNoCP(true)
    //   }

    // });

    //Export cp CMP
    axios
      .defaultAxios({
        url:
          axios.defaultAxios.defaults.baseURL +
          "/measure/getAllcpFromCMP?nbPhoto=" +
          exportDev.nbPhotos,
        method: "GET",
        responseType: "blob", // important
      })
      .then((response) => {
        console.log("response from downloadDev2 => ");
        console.log(response.data);
        console.log(response.data.size);
        if (response.data.size > 0) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "photosMultiple.zip");
          document.body.appendChild(link);
          link.click();
        } else {
          //msg erreur
          console.log("pas de fichier a télécharger");
          setOpenMsgErrorMsgNoCP(true);
        }
      });
  }

  const handleClickOpenSupp = async (id) => {
    // console.log(id)
    setRowToSupp(id);
    setOpenSingleSuppPopup(true);
    //disable measure
  };

  const handleCloseMultipleSupp = () => {
    setOpenMultipleSuppPopup(false);
  };
  const handleCloseSingleSupp = () => {
    setOpenSingleSuppPopup(false);
  };

  const handleClickSuppMulltiple = async () => {
    var ids = tabRowChecked;
    console.log("supp ids");
    console.log(ids);
    for (var i = 0; i < ids.length; i++) {
      await axios.defaultAxios.put(
        axios.defaultAxios.defaults.baseURL + "/measure/disableMes",
        {
          id: ids[i]._id,
        }
      );
    }

    handleCloseMultipleSupp();
    setStateFormFilter({
      id: "",
      id1: "",
      id2: "",
      long: "",
      larg: "",
      haut: "",
      pds: "",
      date: "",
      date2: "",
      term: "",
      type: "",
      exp: "",
      des: "",
      vol: "",
      siteMes: "",
    });
    setTabRowChecked([]);
    //then call refresh
    callSimple();
  };

  const handleClickSuppSingle = async () => {
    await axios.defaultAxios.put(
      axios.defaultAxios.defaults.baseURL + "/measure/disableMes",
      {
        id: rowToSupp,
      }
    );

    // //delete api
    // await axios.defaultAxios.delete(axios.defaultAxios.defaults.baseURL + '/measure', {
    //   data: {
    //       id: id
    //   }
    // })

    handleClose();
    handleCloseSingleSupp();

    setStateFormFilter({
      id: "",
      id1: "",
      id2: "",
      long: "",
      larg: "",
      haut: "",
      pds: "",
      date: "",
      date2: "",
      term: "",
      type: "",
      exp: "",
      des: "",
      vol: "",
      siteMes: "",
    });
    callSimple();
  };

  const clickSuppMultiple = async () => {
    setOpenMultipleSuppPopup(true);
  };

  return (
    <div className={classes.body}>
      <NavBar
        pageCallBack={callBackNav}
        dataFromParent={"mesurePage"}
        dataIsUser={infsplit[1]}
        className={isNavBarOpen ? classes.navOpen : classes.navClose}
      />
      <div
        className={isNavBarOpen ? classes.rootwNavOpen : classes.rootwNavClose}
      >
        <Route path="/logoutApp" component={Redirect} loc={loc} />
        {/* <div className={classes.backgroundDiv} /> */}

        <div className={classes.entete}>
          {/* <h1>{t('MeasureTable.title')}</h1>
          <div>
            <Button onClick={() => changeLanguage('fr')}>fr</Button>
            <Button onClick={() => changeLanguage('en')}>en</Button>
          </div>
          <Button onClick={() => ResetFilters()}>{t('MeasureTable.Button.resetFilter')}</Button>
          <Button onClick={() => resetVolChecked()}>Réinitialiser le volume</Button> */}
          {/* <Button onClick={() => changeLanguage('fr')}>fr</Button>
          <Button onClick={() => changeLanguage('en')}>en</Button> */}
          <div className={classes.volumeTotalDiv}>
            <span className={classes.labelVol}>
              {t("MeasureTable.mesTT")}
              {startDate}
            </span>
            <h1 className={classes.displayVol}>
              <img src={imgSquareMes} alt="square" className={classes.square} />
              <div className={classes.volTTandSELECT}>
                {volumeTotal}
                <Select
                  id="unitVolumeTT"
                  className={classes.selectVolumeTT}
                  variant="outlined"
                  value={unitVolTT}
                  defaultValue={0}
                  onChange={handleChangeUnitVolumeTT()}
                >
                  <MenuItem value={"M"}>{"M³"}</MenuItem>
                  <MenuItem value={"CM"}>{"cm³"}</MenuItem>
                  <MenuItem value={"FT"}>{"Ft³"}</MenuItem>
                  <MenuItem value={"L"}>{"L"}</MenuItem>
                </Select>
              </div>
              {/* {unitMesure === "cm" ? "M³" : "Ft³"} */}
            </h1>
            <div className={classes.displayNBTT}>
              {t("MeasureTable.NbTTdisplay")} {pagination.entriesLength}
            </div>
          </div>
          <div className={classes.rightSideEntete}>
            <div className={classes.dateDiv}>
              <span className={classes.dateTxt}>
                {identityUser.firstname} {identityUser.name} -{" "}
                <span className={classes.entName}>{identityUser.ent}</span>
              </span>
            </div>
            <Divider />
            <div className={classes.btnsReinit}>
              {identityUser.role === "adminPiink" ? (
                <Button
                  className={classes.btnOneReinit}
                  startIcon={
                    <img
                      src={icnDownload}
                      alt="downloadPC"
                      className={classes.icnDwl}
                    />
                  }
                  onClick={opentExportDev}
                >
                  Download Dev
                </Button>
              ) : (
                <span></span>
              )}
              {/* <Button
                className={classes.btnOneReinit}
                onClick={() => resetVolChecked()}
              >
                {t("MeasureTable.Button.resetVol")}
              </Button> */}
              {/* <Button
                className={classes.btnTwoReinit}
                onClick={() => ResetFilters()}
              >
                {t("MeasureTable.Button.resetFilter")}
              </Button> */}
              {/* <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="tableMeasure"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Download as XLS" /> */}
              <Button
                className={classes.btnOneReinit}
                startIcon={
                  <img
                    src={icnDownload}
                    alt="downloadPC"
                    className={classes.icnDwl}
                  />
                }
                onClick={openExportPopup}
              >
                {t("MeasureTable.Button.download")}
              </Button>
              {/* <Button className={classes.btnOneReinit} onClick={openExportPopup2}>Download multiple Point clouds</Button> */}
              <ExcelFile
                element={
                  <button className={classes.btnexport} ref={buttonExportRef}>
                    Download Data With Styles
                  </button>
                }
              >
                <ExcelSheet data={exportExcel} name="Measures">
                  <ExcelColumn
                    label={t("MeasureTable.TableCell.idMeasure")}
                    value="id"
                  />
                  <ExcelColumn label="idUnique" value="idUnique" />
                  {/* {identityUser.role === "adminPiink" ? <ExcelColumn label={t('MeasureTable.TableCell.trackingCol')} value="id1" /> : <p></p>} */}
                  <ExcelColumn
                    label={t("MeasureTable.TableCell.pdsMeasure")}
                    value="weight"
                  />
                  <ExcelColumn
                    label={t("MeasureTable.TableCell.longMeasure")}
                    value="length"
                  />
                  <ExcelColumn
                    label={t("MeasureTable.TableCell.largMeasure")}
                    value="width"
                  />
                  <ExcelColumn
                    label={t("MeasureTable.TableCell.hautMeasure")}
                    value="height"
                  />
                  <ExcelColumn
                    label={t("MeasureTable.TableCell.longMeasure") + " Manuel"}
                    value="lengthManuel"
                  />
                  <ExcelColumn
                    label={t("MeasureTable.TableCell.largMeasure") + " Manuel"}
                    value="widthManuel"
                  />
                  <ExcelColumn
                    label={t("MeasureTable.TableCell.hautMeasure") + " Manuel"}
                    value="heightManuel"
                  />
                  <ExcelColumn label="angle 1" value="angle1" />
                  <ExcelColumn label="distance 1" value="dist1" />
                  <ExcelColumn label="angle 2" value="angle2" />
                  <ExcelColumn label="distance 2" value="dist2" />
                  {/* <ExcelColumn label={t('MeasureTable.TableCell.volMeasure')} value="name" /> */}
                  <ExcelColumn
                    label={t("MeasureTable.TableCell.siteAssoc")}
                    value="siteMeasure"
                  />
                  <ExcelColumn
                    label={t("MeasureTable.TableCell.dateMeasure")}
                    value="createdAt"
                  />
                  <ExcelColumn
                    label={t("MeasureTable.TableCell.devMeasure")}
                    value="device"
                  />
                  <ExcelColumn label="commentaire" value="commentaire" />
                </ExcelSheet>
              </ExcelFile>
              {/* <Button className={classes.btnOneReinit} onClick={() => exportSheet()}>Export excel</Button> */}
            </div>
          </div>
        </div>

        <Paper className={classes.tableau}>
          <Table className={classes.table} id="tableMeasure">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    indeterminate={
                      tabRowChecked.length > 0 &&
                      tabRowChecked.length < measures.length
                    }
                    checked={
                      tabRowChecked.length > 0 &&
                      tabRowChecked.length === measures.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                    style={{ color: "rgba(57, 190, 239, 1)" }}
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("MeasureTable.TableCell.idMeasure")}
                </TableCell>
                {identityUser.role === "adminPiink" ? (
                  <TableCell className={classes.tableCell}>
                    {t("MeasureTable.TableCell.trackingCol")}
                  </TableCell>
                ) : (
                  <th></th>
                )}
                <TableCell className={classes.tableCell}>
                  {t("MeasureTable.TableCell.pdsMeasure")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("MeasureTable.TableCell.longMeasure")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("MeasureTable.TableCell.largMeasure")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("MeasureTable.TableCell.hautMeasure")}
                </TableCell>
                <TableCell className={classes.volumeTableCell}>
                  <div className={classes.volumeLabel}>
                    {t("MeasureTable.TableCell.volMeasure")}
                  </div>
                  <Select
                    id="unitVolume"
                    className={classes.selectInfSupLabel}
                    variant="outlined"
                    value={unitVol}
                    defaultValue={0}
                    onChange={handleChangeUnitVolume()}
                  >
                    <MenuItem value={"M"}>{"M³"}</MenuItem>
                    <MenuItem value={"CM"}>{"cm³"}</MenuItem>
                    <MenuItem value={"FT"}>{"Ft³"}</MenuItem>
                    <MenuItem value={"L"}>{"L"}</MenuItem>
                  </Select>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("MeasureTable.TableCell.siteAssoc")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("MeasureTable.TableCell.dateMeasure")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("MeasureTable.TableCell.devMeasure")}
                </TableCell>
                {identityUser.role === "adminPiink" ? (
                  <TableCell className={classes.tableCell}>
                    <Button
                      type="button"
                      disabled={stateSuppMultiple}
                      onClick={(e) => clickSuppMultiple()}
                    >
                      {stateSuppMultiple === false ? (
                        <img src={imgSupp} alt="suppMulti" />
                      ) : (
                        <img src={imgSuppDisable} alt="suppMulti" />
                      )}
                    </Button>
                  </TableCell>
                ) : (
                  <TableCell></TableCell>
                )}
                {/* <TableCell>{t('MeasureTable.TableCell.typeMeasure')}</TableCell> */}
                {/* <TableCell align="left"><Switch checked={checked} onChange={handleChangeCollapse} /></TableCell> */}
              </TableRow>
              <TableRow className={classes.filtrageRow}>
                <TableCell></TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="id"
                      variant="outlined"
                      value={stateFormFilter.id}
                      onChange={handleChangeFilter("id")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                {identityUser.role === "adminPiink" ? (
                  <TableCell>
                    <Collapse in={checked}>
                      <TextField
                        className={classes.txtfield}
                        margin="dense"
                        id="expe"
                        variant="outlined"
                        value={stateFormFilter.id1}
                        onChange={handleChangeFilter("id1")}
                        onKeyPress={(ev) => pressEnter(ev)}
                      />
                    </Collapse>
                  </TableCell>
                ) : (
                  <th></th>
                )}

                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="pds"
                      variant="outlined"
                      value={stateFormFilter.pds}
                      onChange={handleChangeFilter("pds")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="long"
                      variant="outlined"
                      value={stateFormFilter.long}
                      onChange={handleChangeFilter("long")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="larg"
                      variant="outlined"
                      value={stateFormFilter.larg}
                      onChange={handleChangeFilter("larg")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="haut"
                      variant="outlined"
                      value={stateFormFilter.haut}
                      onChange={handleChangeFilter("haut")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <div style={{ display: "flex" }}>
                      <Select
                        id="supOrInf"
                        className={classes.selectInfSup}
                        variant="outlined"
                        value={supOrInfVar}
                        defaultValue={0}
                        onChange={handleChangeSupOrInf()}
                      >
                        <MenuItem value={0}>{">"}</MenuItem>
                        <MenuItem value={1}>{"≥"}</MenuItem>
                        {/* <MenuItem value={2}>{"="}</MenuItem> */}
                        <MenuItem value={3}>{"≤"}</MenuItem>
                        <MenuItem value={4}>{"<"}</MenuItem>
                      </Select>
                      <TextField
                        className={classes.txtfield}
                        margin="dense"
                        id="vol"
                        type="number"
                        variant="outlined"
                        value={stateFormFilter.vol}
                        onChange={handleChangeFilter("vol")}
                        onKeyPress={(ev) => pressEnter(ev)}
                      />
                    </div>
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    {identityUser.role !== "adminPiink" ? (
                      <TextField
                        className={classes.txtfield}
                        margin="dense"
                        id="siteMes"
                        variant="outlined"
                        value={stateFormFilter.siteMes}
                        onChange={handleChangeFilter("siteMes")}
                        onKeyPress={(ev) => pressEnter(ev)}
                      />
                    ) : (
                      <Autocomplete
                        value={stateFormFilter.siteMes}
                        onChange={(event, newValue) => {
                          // console.log(newValue)
                          // console.log(newValue.inputValue)
                          if (newValue && newValue.inputValue) {
                            handleChangeSiteMesFilter(newValue.inputValue);
                            return;
                          }
                          handleChangeSiteMesFilter(newValue);
                        }}
                        id="siteMes"
                        className={classes.textField}
                        options={sitesField.listSite.map(
                          (option) =>
                            option.entrepriseSite.nomEntreprise +
                            " - " +
                            option.nomSite
                        )}
                        // getOptionLabel={option => option.nomEntreprise}
                        // onChange={handleChange('entrepriseSite')}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: 200 }}
                            label="sites"
                            variant="outlined"
                            onKeyPress={(ev) => pressEnter(ev)}
                          />
                        )}
                      />
                    )}
                  </Collapse>
                </TableCell>
                <TableCell className={classes.tableCellDate}>
                  <Collapse in={checked}>
                    <div>
                      <label className={classes.txtfieldDate}>
                        {t("MeasureTable.dateD")}
                      </label>
                      <TextField
                        className={classes.txtfieldD}
                        margin="dense"
                        id="date"
                        type="date"
                        variant="outlined"
                        value={stateFormFilter.date}
                        onChange={handleChangeFilter("date")}
                        onKeyPress={(ev) => pressEnter(ev)}
                      />
                    </div>
                    <div>
                      <label className={classes.txtfieldDate}>
                        {t("MeasureTable.dateF")}
                      </label>
                      <TextField
                        className={classes.txtfieldD}
                        margin="dense"
                        id="date2"
                        type="date"
                        variant="outlined"
                        value={stateFormFilter.date2}
                        onChange={handleChangeFilter("date2")}
                        onKeyPress={(ev) => pressEnter(ev)}
                      />
                    </div>
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Collapse in={checked}>
                    <TextField
                      className={classes.txtfield}
                      margin="dense"
                      id="term"
                      variant="outlined"
                      value={stateFormFilter.term}
                      onChange={handleChangeFilter("term")}
                      onKeyPress={(ev) => pressEnter(ev)}
                    />
                  </Collapse>
                </TableCell>
                <TableCell>
                  <Button onClick={() => ResetFilters()}>
                    <img src={imgResetFilters} alt="detail" />
                  </Button>
                </TableCell>

                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {isDataCharging ? (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                // <div>
                //   <CircularProgress />
                // </div>
                measures.map((row) => (
                  <TableRow
                    hover
                    key={row._id}
                    // className={row.state === true ? classes.rowDone : classes.rowTable}
                    className={
                      isSelectedDetail(row._id)
                        ? classes.rowSelected
                        : isSelected(row._id)
                        ? classes.rowTable
                        : ""
                      // classes.rowTable
                    }
                    selected={isSelected(row._id)}
                  >
                    <TableCell>
                      <Checkbox
                        key={row._id}
                        checked={isSelected(row._id)}
                        onClick={(event) => selectRow(event, row)}
                        style={{ color: "rgba(57, 190, 239, 1)" }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableCellID}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {row.id}
                    </TableCell>
                    {identityUser.role === "adminPiink" ? (
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                        onClick={(event) => selectRow(event, row)}
                      >
                        {row.id1}
                      </TableCell>
                    ) : (
                      <div></div>
                    )}
                    <TableCell
                      className={classes.tableCell}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {row.weight}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {row.length}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {row.width}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {row.height}
                    </TableCell>
                    {/* ici */}
                    <TableCell
                      className={classes.tableCell}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {callCalculVol(row)}
                    </TableCell>
                    {/* <TableCell onClick={event => selectRow(event, row)} className={classes.tableCell}>{unitMesure === "cm" ? (row.length.split(' ')[0] * row.width.split(' ')[0] * row.height.split(' ')[0] / 1000000).toFixed(3) : ((row.length.split(' ')[0]) * row.width.split(' ')[0] * row.height.split(' ')[0] / 1728).toFixed(3)} {unitMesure === "cm" ? "m³" : "ft³"}</TableCell> */}

                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {row.siteMeasure.entrepriseSite != null
                        ? row.siteMeasure.entrepriseSite.nomEntreprise
                        : ""}{" "}
                      - {row.siteMeasure.nomSite}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {new Intl.DateTimeFormat(i18n.language, {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(new Date(row.createdAt))}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={(event) => selectRow(event, row)}
                    >
                      {row.device}
                    </TableCell>
                    {/* <TableCell className={classes.tableCell}>{row.type}</TableCell> */}
                    <TableCell align="left">
                      <Button type="button" onClick={(e) => clickDetail(row)}>
                        <img src={imgDetail} alt="detail" />
                      </Button>
                    </TableCell>
                    {/* <img
                    width="170"
                    height="300"
                    src={row.photo}
                    alt="without ar"/>
                  <img
                    width="170"
                    height="300"
                    src={row.arphoto}
                    alt="ARPhoto"/> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={pagination.entriesLength}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t("MeasureTable.mesPerPage")}
          />
        </Paper>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <div className={classes.dialogHead}>
            <div id="form-dialog-title" className={classes.btnNext}>
              {t("MeasureTable.detail")}
            </div>
            <div className={classes.btnsRight2}>
              <Button onClick={handleClose}>
                <img
                  src={imgclosepop}
                  className={classes.btnclosePop}
                  alt="close"
                />
              </Button>
            </div>
            <div className={classes.btnsRight}>
              <Button
                onClick={prevMes}
                className={
                  prevDisabled ? classes.btnPrev2Disable : classes.btnPrev2
                }
                disabled={prevDisabled}
              >
                {"^"}
              </Button>
              <Button
                onClick={nextMes}
                className={
                  nextDisabled ? classes.btnPrev1Disable : classes.btnPrev1
                }
                disabled={nextDisabled}
              >
                {"^"}
              </Button>
            </div>
          </div>
          <Divider className={classes.separatorPopupDetail} />

          <DialogContent className={classes.dialogDetail}>
            <DialogContentText>
              <Paper className={classes.root}>
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab label={t("MeasureTable.photo1")} />
                  {stateForm.photoAr !== "data:;base64,ZmFsc2U=" ? (
                    <Tab label={t("MeasureTable.photo2")} />
                  ) : (
                    <span></span>
                  )}
                </Tabs>
                <TabPanel value={value} index={0}>
                  <span className={classes.photoPopup}>
                    <img
                      src={stateForm.photo}
                      alt="photodetail"
                      className={classes.imgDisplay}
                    />
                  </span>
                </TabPanel>
                {stateForm.photoAr !== "data:;base64,ZmFsc2U=" ? (
                  <TabPanel value={value} index={1}>
                    {/* <span>{stateForm.photoAr}</span> */}
                    <span className={classes.photoPopup}>
                      <img
                        src={stateForm.photoAr}
                        alt="photoARdetail"
                        className={classes.imgDisplay}
                      />
                    </span>
                  </TabPanel>
                ) : (
                  <span></span>
                )}
              </Paper>
              {/* <div className={classes.photoPopup}><img src={stateForm.photo} alt="photodetail" className={classes.imgDisplay} /></div> */}
              {/* <img src={stateForm.photoAr} alt="photodetail ar" className={classes.photoPopup}/> */}
              {/* Add fields photo, photo ar */}
            </DialogContentText>
            <div className={classes.txtPopDetail}>
              {t("NavBar.mesure")}{" "}
              {identityUser.langue === "FR"
                ? "(L x l x H) : "
                : "(L x W x H) : "}
              <span className={classes.txtPopDetail2}>
                {measureDetail.length} x {measureDetail.width} x{" "}
                {measureDetail.height}{" "}
              </span>
            </div>
            {identityUser.role === "adminPiink" ? (
              <div className={classes.alltextFieldMes}>
                <TextField
                  margin="dense"
                  id="model"
                  className={classes.textFieldMes}
                  label={t("MeasureTable.lengthMan")}
                  type="number"
                  // label={t('TerminalTab.model')}
                  variant="outlined"
                  defaultValue={stateForm.lengthManuel}
                  onChange={handleChange("lengthManuel")}
                />
                <TextField
                  margin="dense"
                  id="model"
                  className={classes.textFieldMes}
                  label={t("MeasureTable.widthMan")}
                  type="number"
                  // label={t('TerminalTab.model')}
                  variant="outlined"
                  defaultValue={stateForm.widthManuel}
                  onChange={handleChange("widthManuel")}
                />
                <TextField
                  margin="dense"
                  id="model"
                  className={classes.textFieldMes}
                  label={t("MeasureTable.heightMan")}
                  type="number"
                  // label={t('TerminalTab.model')}

                  variant="outlined"
                  defaultValue={stateForm.heightManuel}
                  onChange={handleChange("heightManuel")}
                />
              </div>
            ) : (
              <div></div>
            )}

            <div className={classes.txtPopDetail}>
              {t("MeasureTable.adrMac")} :{" "}
              <span className={classes.txtPopDetail2}>{stateForm.baseMac}</span>
            </div>
            {identityUser.role === "adminPiink" ? (
              <div>
                <Divider className={classes.separatorPopupTxt} />
                <div className={classes.txtPopDetail}>
                  {t("MeasureTable.dist1")} :{" "}
                  <span className={classes.txtPopDetail2}>
                    {stateForm.dist1}
                  </span>
                </div>
                <Divider className={classes.separatorPopupTxt} />
                <div className={classes.txtPopDetail}>
                  {t("MeasureTable.dist2")} :{" "}
                  <span className={classes.txtPopDetail2}>
                    {stateForm.dist2}
                  </span>
                </div>
                <Divider className={classes.separatorPopupTxt} />
                <div className={classes.txtPopDetail}>
                  {t("MeasureTable.angle1")} :{" "}
                  <span className={classes.txtPopDetail2}>
                    {stateForm.angle1}
                  </span>
                </div>
                <Divider className={classes.separatorPopupTxt} />
                <div className={classes.txtPopDetail}>
                  {t("MeasureTable.angle2")} :{" "}
                  <span className={classes.txtPopDetail2}>
                    {stateForm.angle2}
                  </span>
                </div>
                <Divider className={classes.separatorPopupTxt} />
                <div className={classes.txtPopDetail}>
                  {t("MeasureTable.typeOfM")} :{" "}
                  <span className={classes.txtPopDetail2}>
                    {measureDetail.typeOfMeasure}
                  </span>
                </div>
                <Divider className={classes.separatorPopupTxt} />
                <div className={classes.txtPopDetail}>
                  {t("MeasureTable.modeMes")} :{" "}
                  <span className={classes.txtPopDetail2}>
                    {measureDetail.mode}
                  </span>
                </div>
                <Divider className={classes.separatorPopupTxt} />
                <div className={classes.txtPopDetail}>
                  {t("MeasureTable.tempTof")} :{" "}
                  <span className={classes.txtPopDetail2}>
                    {measureDetail.temperatureTOF} °C
                  </span>
                </div>
              </div>
            ) : (
              <span></span>
            )}
            <div className={classes.bottomPopupDetail}>
              <div className={classes.lineDownoadPointCloud}>
                {identityUser.role === "adminPiink" ? (
                  <Button
                    variant="contained"
                    className={classes.btnDownloadPC}
                    startIcon={<img src={dwlPointCloud} alt="downloadPC" />}
                    onClick={(event) => download(event, measureDetail.idUnique)}
                  >
                    Download point cloud
                  </Button>
                ) : (
                  // <button onClick={event => download(event, measureDetail.idUnique)} color="primary" className={classes.btnDownloadPC}>Download point cloud</button>
                  <span></span>
                )}

                <div className={classes.divFormState}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.btnState}
                        onChange={handleChange("btnState")}
                        value="btnState"
                        color="primary"
                      />
                    }
                    label={t("MeasureTable.state")}
                    labelPlacement="start"
                  />
                </div>
              </div>
              <div className={classes.msgError}></div>
              <Collapse in={openMsgErrorMsgNoCP}>
                <Alert
                  severity="error"
                  onClose={() => {
                    setOpenMsgErrorMsgNoCP(false);
                  }}
                >
                  {t("error.errorMsgNoCP")}
                </Alert>
              </Collapse>

              <div className={classes.divForm}>
                <TextField
                  margin="dense"
                  id="name"
                  multiline
                  label={t("MeasureTable.com")}
                  className={classes.textFieldComm}
                  rows="4"
                  variant="outlined"
                  value={stateForm.comm}
                  onChange={handleChange("comm")}
                />
              </div>
              {identityUser.role === "adminPiink" ? (
                // <TextField
                //   margin="dense"
                //   id="site"
                //   label={t('MeasureTable.siteMes')}
                //   className={classes.textField}
                //   rows="4"
                //   variant="outlined"
                //   defaultValue={stateForm.siteMes}
                //   onChange={handleChange('siteMes')}
                // />
                <div>
                  <span className={classes.txtPopDetail}>Site :</span>
                  <div className={classes.textFieldComm}>
                    <Autocomplete
                      value={stateForm.siteMes}
                      onChange={(event, newValue) => {
                        // console.log(newValue)
                        // console.log(newValue.inputValue)
                        if (newValue && newValue.inputValue) {
                          handleChangeSite(newValue.inputValue);
                          return;
                        }
                        handleChangeSite(newValue);
                      }}
                      id="siteMes"
                      options={site.listSite.map((option) => option.nomSite)}
                      // getOptionLabel={option => option.nomEntreprise}
                      // onChange={handleChange('entrepriseSite')}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // className={classes.selectBoxAuto}
                          // label={t('TerminalTab.siteAssoc')}
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </div>
              ) : (
                <span></span>
              )}
            </div>
          </DialogContent>
          <DialogActions className={classes.footerPop}>
            {identityUser.role === "adminPiink" ? (
              <Button
                onClick={(e) => handleClickOpenSupp(measureDetail._id)}
                color="primary"
                className={classes.btnPopupSupp1}
                startIcon={
                  <img
                    src={icnTrash}
                    alt="downloadPC"
                    className={classes.icnDwl}
                  />
                }
              >
                {t("Buttons.suppr")}
              </Button>
            ) : (
              <span></span>
            )}
            <Button
              onClick={handleValidate}
              color="primary"
              className={classes.btnPopupSupp2}
            >
              {t("MeasureTable.save")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openMultipleSuppPopup}
          onClose={handleCloseMultipleSupp}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className={classes.PopupSupp}>
            <div className={classes.imgWarningSupp}>
              <img src={imgWarningSupp} alt="warningSupp" />
            </div>
            <div className={classes.titleSupp}>SUPPRIMER</div>
            <div className={classes.txtPopupSupp}>
              Êtes vous sûr de vouloir <br />
              supprimer la selection ?
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseMultipleSupp}
              color="primary"
              className={classes.btnPopupSupp1}
            >
              {t("Buttons.annuler")}
            </Button>
            <Button
              onClick={handleClickSuppMulltiple}
              color="primary"
              className={classes.btnPopupSupp2}
            >
              {t("MeasureTable.valid")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSingleSuppPopup}
          onClose={handleCloseSingleSupp}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className={classes.PopupSupp}>
            <div className={classes.imgWarningSupp}>
              <img src={imgWarningSupp} alt="warningSupp" />
            </div>
            <div className={classes.titleSupp}>SUPPRIMER</div>
            <div className={classes.txtPopupSupp}>
              Êtes vous sûr de vouloir <br />
              supprimer la mesure ?
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseSingleSupp}
              color="primary"
              className={classes.btnPopupSupp1}
            >
              {t("Buttons.annuler")}
            </Button>
            <Button
              onClick={handleClickSuppSingle}
              color="primary"
              className={classes.btnPopupSupp2}
            >
              {t("MeasureTable.valid")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openLoad}
          onClose={hideCircularProgress}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className={classes.dialogLoadDetail}>
            <CircularProgress />
          </DialogContent>
        </Dialog>

        {/* <Dialog open={openExport2} onClose={handleCloseExport2} aria-labelledby="form-dialog-title">
          <DialogContent>
            <div>
              export multiple cp
            </div>

            date :
            <TextField
              className={classes.txtfieldD}
              margin="dense"
              id="date"
              type="date"
              variant="outlined"
              value={dateExport.dateD}
              onChange={handleChangedateDexport("dateD")}
              onKeyPress={(ev) => pressEnter(ev)}
            />
            <label className={classes.txtfieldDate}>
              {t('MeasureTable.dateF')}
            </label>
            <TextField
              className={classes.txtfieldD}
              margin="dense"
              id="date"
              type="date"
              variant="outlined"
              value={dateExport.dateF}
              onChange={handleChangedateDexport('dateF')}
              onKeyPress={(ev) => pressEnter(ev)}
            />
            <Divider className={classes.separatorRightBar} />
            site : 
            <div className={classes.divForm}>
              <Autocomplete
                value={siteToExport}
                onChange={(event, newValue) => {
                  // console.log(newValue)
                  // console.log(newValue.inputValue)
                  if (newValue && newValue.inputValue) {
                    handleChangeToExport(newValue.inputValue)
                    return;
                  }
                  handleChangeToExport(newValue)

                }}
                id="siteMes"

                options={site.listSite.map(option => option.nomSite)}
                // getOptionLabel={option => option.nomEntreprise}
                // onChange={handleChange('entrepriseSite')}

                renderInput={params =>
                  <TextField {...params}
                    // className={classes.selectBoxAuto}
                    // label={t('TerminalTab.siteAssoc')} 
                    variant="outlined"
                  />}

              />
            </div>
            <Divider className={classes.separatorRightBar} />
            mode :
            <TextField
              className={classes.txtfield}
              margin="dense"
              id="haut"
              variant="outlined"
              value={exportMultiMode.mode}
              onChange={handleChangeMode("mode")}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseExport2} color="primary">
              {t('MeasureTable.fermer')}
            </Button>
            <Button onClick={event => downloadMultiple(event)} color="primary">
              {t('MeasureTable.valid')}
            </Button>
          </DialogActions>
        </Dialog> */}

        <Dialog
          open={openExportDev}
          onClose={handleCloseExportDev}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <div>DLDev</div>
            nb photo to dl :
            <TextField
              className={classes.txtfield}
              margin="dense"
              id="haut"
              variant="outlined"
              value={exportDev.nbPhotos}
              onChange={handleChangenbPhoto("nbPhotos")}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseExportDev} color="primary">
              {t("MeasureTable.fermer")}
            </Button>
            <Button onClick={(event) => downloadDev(event)} color="primary">
              {t("MeasureTable.valid")}
            </Button>
            <Button onClick={(event) => downloadDev2(event)} color="primary">
              Download CMP
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openExport}
          onClose={handleCloseExport}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <h3 className={classes.titleExport}>
              {t("MeasureTable.selectDateExp")}
            </h3>

            <Divider className={classes.separatorPopup} />

            <label className={classes.txtfieldDate}>
              {t("MeasureTable.dateD")}
            </label>
            <TextField
              className={classes.txtfieldDPopup}
              margin="dense"
              id="date"
              type="datetime-local"
              variant="outlined"
              value={dateExport.dateD}
              onChange={handleChangedateDexport("dateD")}
              onKeyPress={(ev) => pressEnter(ev)}
            />
            <label className={classes.txtfieldDate}>
              {t("MeasureTable.dateF")}
            </label>
            <TextField
              className={classes.txtfieldDPopup}
              margin="dense"
              id="date"
              type="datetime-local"
              variant="outlined"
              value={dateExport.dateF}
              onChange={handleChangedateDexport("dateF")}
              onKeyPress={(ev) => pressEnter(ev)}
            />
            <Collapse in={openMsgErrorDateExp}>
              <Alert
                severity="error"
                onClose={() => {
                  setOpenMsgErrorDateExp(false);
                }}
              >
                {t("error.errorMsgDateExp")}
              </Alert>
            </Collapse>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleCloseExport} color="primary">
              {t('MeasureTable.fermer')}
            </Button> */}
            <Button
              className={classes.btnPopupExport}
              onClick={callExportExcel}
              color="primary"
            >
              {t("MeasureTable.valid")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {rightBarState ? (
        <Drawer
          className={classes.barreLatDroiteOpen}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper2,
          }}
          anchor="right"
        >
          {/* logout */}
          <Button
            className={classes.btnCloseNavDroite}
            onClick={(e) => toggleRightBarPanel(true)}
          >
            {" "}
            <img src={imgbtnClose} alt="bouton close" />{" "}
          </Button>
          v2.0.15
          <h2 className={classes.userNameBarLatOpen}>
            {identityUser.name} {identityUser.firstname}
          </h2>
          <span className={classes.typeUserBarLatOpen}>
            {identityUser.role}
          </span>
          <span className={classes.EntrepriseBarLatOpen}>
            {identityUser.ent}
          </span>
          <Divider className={classes.separatorRightBar} />
          <div className={classes.langDiv}>
            <IconButton onClick={() => changeLanguage("fr")}>
              <img src={drapeauFr} alt="drapeau francais" />
            </IconButton>
            <IconButton onClick={() => changeLanguage("en")}>
              <img src={drapeauEn} alt="drapeau anglais" />
            </IconButton>
            {/* <Button onClick={() => changeLanguage('fr')}>icone drapeau fr</Button>
              <Button onClick={() => changeLanguage('en')}>en flag icon</Button> */}
          </div>
          <Divider className={classes.separatorRightBar} />
          <span className={classes.labelRightBar}>unité de mesure</span>
          <Select
            id="selectUnit"
            className={classes.selectUnitMes}
            variant="outlined"
            value={unitMesure}
            onChange={handleChangeUnitMes()}
          >
            <MenuItem value={"cm"}>{"cm"}</MenuItem>
            <MenuItem value={"in"}>{"inch"}</MenuItem>
          </Select>
          <Divider className={classes.separatorRightBar} />
          <span className={classes.labelRightBar}>filtres de recherche</span>
          <FormControlLabel
            className={classes.switchFilter}
            control={
              <IOSSwitch
                // className={classes.switchFilter}
                checked={checked}
                onChange={handleChangeCollapse}
              />
            }
            label={checked ? "On" : "Off"}
          />
          <Button
            variant="contained"
            className={classes.btnLogout}
            endIcon={<img src={logoutIcon} alt="logout" />}
            onClick={() => logout()}
          >
            {t("Buttons.returnHub")}
          </Button>
          {/* <button onClick={e => toggleRightBarPanel(true)} className={classes.btnRightBar2} /> */}
        </Drawer>
      ) : (
        <Drawer
          className={classes.barreLatDroiteClose}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="right"
        >
          <Button
            className={classes.btnOpenNavDroite}
            onClick={(e) => toggleRightBarPanel(false)}
          >
            {" "}
            <img
              src={imgbtnSettings}
              alt="settings"
              className={classes.imgbtnset}
            />{" "}
          </Button>
          <span className={classes.userSideTxt}></span>
          {/* <button onClick={e => toggleRightBarPanel(false)} className={classes.btnRightBar} /> */}
        </Drawer>
      )}
    </div>
  );
}

// MeasureTable.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default MeasureTable;
