import React from 'react'
import { Link } from '@material-ui/core'
import MeasureTable from '../Components/MeasureTable'
import { useTranslation } from 'react-i18next';
import Login from '../Components/Login'
// import MeasureTable from '../Components/MeasureTable'
// import NavBar from '../Components/NavBar'

function HomePage() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      {/* <h2>Home</h2> */}
      {/* <Link to="/MesurePage"> */}
      {/* <Link href="/mesures"> Go to mesure </Link> */}
      {/* </Link> */}
      {/* <h2>{t('MeasureTable.title')}</h2> */}
      <Login />
      {/* <MeasureTable /> */}
    </div>
  )
}

export default HomePage
