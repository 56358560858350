import axios from "axios";

let config = {
  baseURL:
    process.env.baseURL ||
    process.env.apiUrl ||
    "https://hubserver.piink3d.com" + "/api",
  // baseURL: process.env.baseURL || process.env.apiUrl || 'http://35.241.198.191:3701/api',
  //   baseURL:
  //     process.env.baseURL || process.env.apiUrl || "http://192.168.1.18:5002/api",
  // baseURL: process.env.baseURL || process.env.ap'http://192.168.1.17:5001/api'iUrl || 'http://213.32.59.47:5001/api' || https://auth.piink-teknology.com,
  // serverURL: '192.168.1.13:3004'
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const defaultAxios = axios.create(config);

defaultAxios.interceptors.request.use(
  function (config) {
    // console.log("config =>")
    console.log(config);
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
defaultAxios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

const customAxios = axios.create({
  //   baseURL: "http://192.168.1.18:5001/api",
  // baseURL: 'http://35.241.198.191:3601/api'
  baseURL: "https://hubserver.piink-teknology.com/api",
});

customAxios.interceptors.request.use(
  function (config) {
    // console.log("config =>")
    console.log(config);
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
customAxios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

const customAxios2 = axios.create({
  //   baseURL: "http://192.168.1.18:5003/api",
  baseURL: "https://piinkscanserver.piink-teknology.com/api",
});

customAxios2.interceptors.request.use(
  function (config) {
    // console.log("config =>")
    console.log(config);
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
customAxios2.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

defaultAxios.CancelToken = axios.CancelToken;
defaultAxios.isCancel = axios.isCancel;
customAxios.CancelToken = axios.CancelToken;
customAxios.isCancel = axios.isCancel;
customAxios2.CancelToken = axios.CancelToken;
customAxios2.isCancel = axios.isCancel;

export default {
  defaultAxios,
  customAxios,
  customAxios2,
};
