import React from 'react'
import MeasureTable from '../Components/MeasureTable'
import NavBar from '../Components/NavBar'
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    backgroundPage: {
        backgroundColor: "#F2F2F2",
        paddingBottom: "20px"
    }
})

function MesurePage(props) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    return (
        <div className={classes.backgroundPage}>
            
            <MeasureTable {...props}/>
        </div>
    )
}

export default MesurePage
