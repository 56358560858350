import { createContext, useContext } from 'react';
import { useCookies } from 'react-cookie';

export const AuthContext = createContext();

export function useAuth() {
    var isAlreadyAuth = false

    const [cookies, setCookie, removeCookie] = useCookies(['cookie-user']);

    var token = localStorage.getItem('tokens')
    // console.log(token)
    //test si localStorage
    if (token !== "undefined"){
        // console.log("token in local storage")
        isAlreadyAuth = true
        //send info from local storage
    } else {
        // console.log("pas de token !")
        if (cookies["cookie-user"] !== undefined) {
            // console.log("cookie exist")
            //send info from cookies
            isAlreadyAuth = true
        } else {
            console.log("pas de cookie")
        }
    }
    //Si Non test si cookies
    // console.log(cookies["cookie-user"])
    

    //Si Non AuthContext

    return useContext(AuthContext);
}

// function authReducer(state, action) {

//     switch(action.type) {
//         case "login":
//             const { authResult, user } = action
//             const expiresAt = authResult.expiresIn * 1000 + new Date().getTime()

//             localStorage.setItem("expres_at", JSON.stringify(expiresAt))
//             localStorage.setItem("user", JSON.stringify(user))

//             // + cookies

//             return { user, expiresAt }
//         case "logout":
//             localStorage.removeItem("expres_at")
//             localStorage.removeItem("user")

//             // + cookies

//             return { user: {}, expiresAt: null }
//         default:
//             return state
//     }

// }

// export const useAuth = () => {
//     // this is the state stuff
//     const { state, dispatch, auth0 } = useContext(
//         AuthContext
//     );

//     const login = () => {
//         auth0.authorize();
//     };

//     const logout = () => {
//         auth0.logout({
//             returnTo: callback_domain
//         });
//         dispatch({
//             type: "logout"
//         });

//         // Return to the homepage after logout.
//         navigate("/");
//     };

//     const isAuthenticated = () => {
//         return state.expiresAt && new Date().getTime() < state.expiresAt;
//     };

//     return {
//         isAuthenticated,
//         user: state.user,
//         userId: state.user ? state.user.sub : null,
//         login,
//         logout,
//         handleAuthentication
//     };
// };