import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./i18n";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store/index";
import { Provider } from "react-redux";
import axios from "axios";
import cookie from "js-cookie";
import { Route, Redirect } from "react-router-dom";
import jwt from "jsonwebtoken";
import qs from "qs";

const _clientID = "piink3dHub";
console.log("piinkdocker/piink3dhub:v2.0.15");
var axios_customUrl;
if (process.env.NODE_ENV == "production") {
  axios_customUrl = "https://auth.piink-teknology.com";
  // console.log = function () { };
} else {
  // axios_customUrl = "http://192.168.1.13:3004"
  axios_customUrl = "https://auth.piink-teknology.com";
}
const originRoute = window.location.origin;

let tokenCookie = cookie.get("token");
// console.log("tokenCookie :")
// console.log(tokenCookie)
// console.log(route)

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
};

// console.log("window.location ->")
// console.log(window.location.search)

if (tokenCookie) {
  // console.log("test token cookie ok")
  axios.defaults.headers.common["Authorization"] = `Bearer ${tokenCookie}`;
  axios.defaults.baseURL = axios_customUrl;
  axios.get("/user").then((res) => {
    store.dispatch({ type: "SET_LOGIN", payload: res.data });
    render();
  });
} else {
  if (window.location.search != "") {
    // console.log("params present")
    const params = new URLSearchParams(window.location.search);
    var authorizationCode = params.get("authorizationCode");
    console.log(authorizationCode);
    var tokenRequestObj = {
      grant_type: "authorization_code",
      code: authorizationCode,
      client_id: _clientID,
    };

    axios
      .post(
        axios_customUrl + "/api/oauth/authorize/token",
        qs.stringify(tokenRequestObj)
      )
      .then((res) => {
        console.log("create token");
        var token = res.data.access_token;
        cookie.set("token", token);
        //recuperer info user

        axios
          .get(axios_customUrl + "/api/user", {
            params: {
              access_token: res.data.access_token,
              client_id: _clientID,
            },
          })
          .then((userInfo) => {
            //stocker vals
            // console.log("userInfos : ")
            // console.log(userInfo.data)
            cookie.set(
              "infosUser",
              userInfo.data.username + "__" + userInfo.data.role
            );
            //add redirection

            render();
          });
      });
  } else {
    // console.log("baseUrl : " + window.location.origin)

    const requestObj = {
      response_type: "code",
      client_id: _clientID,
      redirect_uri: "/mesures",
    };
    // console.log("call 1")
    axios.defaults.baseURL = axios_customUrl;
    axios
      .get("/Auth", {
        params: requestObj,
      })
      .then((res) => {
        // console.log("call 2")
        // console.log(res)

        // console.log(res.data + `?client_id=${_clientID}` + '&response_type=code' + '&redirect_uri=/mesures')

        // axios.get(res.data + `?client_id=${_clientID}` + '&response_type=code' + '&redirect_uri=/mesures').then(result=> {
        //     console.log(result)
        //     render();
        // })

        //modify below (store location -> porops.location ...)
        // return <Route path= '/privacy-policy' component ={ Redirect } loc={res.data + `?client_id=${_clientID}` +
        //         '&response_type=code' +
        //         '&redirect_uri=' + "/mesures"}

        // />
        // return < Route path = '/privacy-policy' component = {() => {
        //     window.location.href = 'www.google.fr';
        //     return null;
        //     }
        // }/>
        render();

        // redirect(
        //     res.data +
        //     `?client_id=${_clientID}` +
        //     '&response_type=code' +
        //     '&redirect_uri=' +
        //     originRoute
        // )
      });
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
