import React, { useState } from 'react';
import { AuthContext } from "./context/auth";
import { CookiesProvider } from 'react-cookie';
// import logo from './logo.svg';
import './App.css';

import AppRouter from './Routers/'

function App(props) {

  // const [authTokens, setAuthTokens] = useState();

  // const setTokens = (data) => {
  //   localStorage.setItem("tokens", JSON.stringify(data));
  //   setAuthTokens(data);
  // }


  return (
    <div className="App">
      {/* <CookiesProvider> */}
        {/* <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}> */}
          <AppRouter />
        {/* </AuthContext.Provider> */}
      {/* </CookiesProvider> */}
      
      
    </div>
  );
}

export default App;
